import React, {useEffect, useState} from 'react'
import AppLayout from '../../layouts/main.layout'
import {
  Table,
  PageHeader,
  Button
} from "antd";
import {
  Link
} from "react-router-dom";
import {
  PlusOutlined
} from '@ant-design/icons';
import api from '../../lib/api'

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Lease#',
    dataIndex: 'lease_number',
    key: 'lease_number',
    render: (text, record) => <Link to={`leases/view/${record.id}`}>{text}</Link>
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date'
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date'
  },
  {
    title: 'Property',
    dataIndex: ['property', 'name'],
    key: 'property'
  }
];

function Leases () {

  const [leases, setLeases] = useState([])

  useEffect(() => {

    api.get('/api/lease/index')
    .then(response => {
      setLeases(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })

    return () =>  {
      setLeases([])
    }
  }, [])

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Leases"
        subTitle="Listing of all leases"
        extra={[
          <Link to={'/leases/new'} key={1}>
            <Button icon={<PlusOutlined />} />
          </Link>,
        ]}
      />
      <Table columns={columns} dataSource={leases} rowKey={(record) => record.id}/>
    </React.Fragment>
  )
}

const LeasesPage = () => (
  <AppLayout>
    <Leases />
  </AppLayout>
)

export default LeasesPage