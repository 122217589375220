import React from 'react'
import {
  Card,
  Divider,
  Avatar
} from 'antd'

const unSelectedStyle = {
  backgroundColor: '#ecf0f1',
  border: '1px solid #bdc3c7',
  color: '#ccc',
  padding: '10px'
}


function TenantDetailCard (props) {
  const { Meta } = Card
  const { tenant } = props

  if(tenant == null){
    return (
      <div style={unSelectedStyle}>
        No Tenant Selected
      </div>
    )
  }


  return (
    <React.Fragment>
      <Card  title={'Tenant Details'} extra={<a href="!#">More</a>}>
        <Meta
          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
          title={tenant && tenant.name}
          description={
            <div className={'tenant-detail-card'}>
              <p>{tenant && tenant.identification}</p>
              <p>{tenant && tenant.cell_phone_number}</p>
              <p>{tenant && tenant.email}</p>

              <Divider style={{margin: '8px 0px', padding: '0px'}} />

              <p>Status: {tenant && tenant.marital_status}</p>
              <p>Occupation: {tenant && tenant.occupation}</p>
            </div>
          }
        />
      </Card>
    </React.Fragment>
  )
}

export default TenantDetailCard