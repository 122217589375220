import useToggleState from "../hooks/useToggleState";
import {Button, Modal, notification} from "antd";
import api from '../lib/api'
import {useHistory} from "react-router-dom";

function DeleteButton (props) {
  const history = useHistory();
  const [isDeleteModalVisible, toggle] = useToggleState(false)

  const key = `open${Date.now()}`;

  const onConfirm = () => {
    api.delete(props.url)
    .then(response => {
      console.log(response)
      toggle(false)
      notification.info({
        message: props.deleteSuccessTitle,
        description:
          props.deleteSuccessMessage,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        key
      });
      history.push(props.redirectTo)
      if(props.onDeleteAction) props.onDeleteAction()

    })
    .catch(error => {
      console.log(error)
      toggle(false)
      notification.info({
        message: 'Error',
        description:
        `Could not delete ${props.objectType} at this time`,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        key
      });
      history.push(props.redirectTo)
    

    })
  }


  return (
    <>
      <Button type={'danger'} onClick={toggle}>
        {props.text}
      </Button>
      <Modal
        title={'Confirm Deletion'}
        visible={isDeleteModalVisible}
        onOk={onConfirm}
        onCancel={toggle}
      >
        <p>{`Are you sure you want to delete this ${props.objectType}?`}</p>
      </Modal>
    </>
  )
}

export default DeleteButton