import React, {useContext, useState, useEffect} from 'react'
import AppLayout from '../../layouts/main.layout'
import {
  PageHeader,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Form,
  Input,
  Divider,
  Upload,
  notification
} from "antd";
import {
  Link,
  useHistory
} from "react-router-dom";
import {
  CloseOutlined,
  UploadOutlined
} from '@ant-design/icons';
import SelectProperty from './components/SelectProperty'
import SelectTenant from './components/SelectTenant'
import {AuthContext} from "../../contexts/auth.context"
import api from '../../lib/api'
import { useCallback } from 'react'

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
}

const range = (start, end, step) => {
  return Array.from(Array.from(Array(Math.ceil((end-start)/step)).keys()), x => start+ x*step);
}

function LeaseForm (props) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm()
  const [propertyId, setPropertyId] = useState('')
  const [property, setProperty] = useState(null)
  const [propertyError, setPropertyError] = useState(false)
  const [tenant, setTenant] = useState(null)
  const [tenantError, toggleTenantError] = useState(false)
  const { Option } = Select
  const auth = useContext(AuthContext)
  const [file, setFile] = useState(null)
  const history = useHistory();
  const [currencyOptions, setCurrencyOptions] = useState('')

  const SetPropertyDefaultFormValues = useCallback((data) => {
    console.log(data)
    if(data !=null && data.currency != null){
      form.setFieldsValue({
        rent_amount: data.default_rent,
        currency: data.currency.slug,
        security_deposit_amount: data.default_security_deposit,
        utility_deposit_amount: data.default_utility_deposit,
  
      })
    }
    

  }, [form])

  useEffect(() => {
    api.get('/api/currency/index')
    .then(response => {

      let options = []
      let defCurrency = null

      response.data.data.forEach(element => {
        options.push(<Option key={element.slug}> {element.name}</Option>)

      });

      setCurrencyOptions(options)

    })
    .catch(error => {
      console.log(error)
    })


  },[])

  const onSetProperty = (property) => {
    console.log('property set')
    setProperty(property)
    SetPropertyDefaultFormValues(property)
  }


  const onFinish = (values) => {

    if(propertyId === '' || propertyId === null || tenant === null) {
      if (propertyId === '') {
        setPropertyError(true)
      }
      if (tenant === null) {
        toggleTenantError(true)
      }
    }else {
      values.property_id = propertyId
      values.lease_file_id = file
      values.contact_id = tenant.id
      console.log('Success:', values)

      api.post('api/lease/store', values)
      .then(response => {
        console.log(response)
        notification.open({
          message: 'Success',
          description: `Lease ${response.data.data.lease_number} created`,
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });

        history.push('/leases')

      })
      .catch(error => {
        console.log(error)
        if(error.response.status === 422) {
          displayErrors(error.response.data)
        }else{
          displayErrors({errors: ['Something went wrong. Could not complete action']})
        }
      })


      //return <Redirect to={'/leases'} />
    }
  }

  const displayErrors = (data) => {
    const { errors } = data;

    const key = `open${Date.now()}`;

    errors.forEach(err => {
      notification.error({
        message: 'Error',
        description:
          err,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        key
      });
    })
  }

  const onFinishFailed = (errorInfo) => {
    if(propertyId === '' || propertyId === null || tenant === null) {
      if (propertyId === '') {
        setPropertyError(true)
      }
      if (tenant === null) {
        toggleTenantError(true)
      }
    }
    //console.log('Failed:', errorInfo);
  };

  const days = range(1, 32, 1)

  const onFileUploadChange = (data) => {

    console.log(data)

    const {file} = data

    if(file.response !== undefined && file.status === 'done') {
      //console.log('File Uploaded, ID: ',file.response.data.id)
      setFile(file.response.data.id)
    }

    if(file.status === 'removed') {
      console.log('File Removed')
      setFile(null)
    }

    //setFile(file.response.data.id)
  }

  // if(propertyId !== '' && setPropertyError === true) {
  //   setPropertyError()
  // }

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="New Lease"
        subTitle="Create a new lease"
        extra={[
          <Link to={'/leases'} key={'1'}><Button icon={<CloseOutlined />} /></Link>,
        ]}
      />

      <Divider />
      <h3>Property Details</h3>

      <SelectProperty
        setPropertyId={setPropertyId}
        setProperty={onSetProperty}
        error={propertyError}
        toggleError={setPropertyError}
      />

      <Divider />
      <h3>Tenant Details</h3>

      <SelectTenant
        tenant={tenant}
        setTenant={setTenant}
        tenantError={tenantError}
        toggleError={toggleTenantError}
      />

      <Divider />
      <h3>Lease Details</h3>

      <Form
        {...formItemLayout}
        form={form}
        layout={'horizontal'}
        labelAlign={'right'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Form.Item label={'Lease#'} name={'lease_number'} rules={[{ required: true, message: 'Lease Number is required'}]}>
          <Input placeholder={'Lease Number...'} />
        </Form.Item>

        <Form.Item
          label={'Date Range'}
          name="date_range"
          rules={[{ required: true, message: 'Date Range is required' }]}
        >
          <RangePicker bordered={true} />
        </Form.Item>

        <Form.Item
          label={'Num Pax'}
          name={'num_pax'}
          rules={[{ required: true, message: 'Num Pax is required'}]}
        >
          <Input placeholder={'Number of persons...'} style={{ width: 160 }} />
        </Form.Item>

        <Form.Item 
          label={'Currency'} 
          name={'currency'}
          initialValue={"mvr"}
          >
          <Select>
            {currencyOptions}
          </Select>
        </Form.Item>


        <Form.Item label="Rental Details" style={{ marginBottom: 0 }}>
          <Form.Item
            name="rent_amount"
            rules={[{ required: true, message: 'Rent Amount is required' }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input placeholder="Rent Amount..." />
          </Form.Item>
          <Form.Item
            name="grace_period_days"
            rules={[{ required: true, message: 'Rent Due Day is required'}]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
          >
            <Select
              placeholder={'Rent due day of month'}
            >
              {days.map(day => (
                <Option key={day}>{day}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={'Fine %'}
          name={'fine_rate'}
          initialValue={5}
          rules={[{ required: true, message: 'Fine rate is required'}]}
        >
          <Input placeholder={'5%...'} style={{ width: 160 }} />
        </Form.Item>

        <Divider />

        <Row>
          <Col span={4} style={{textAlign: 'right', paddingRight: '8px'}}>
            Lease File (PDF):
          </Col>
          <Col span={14}>
            <Upload
              action="http://rental-mgt-webapi.test/api/file/store"
              listType="picture"
              maxCount={1}
              headers={{
                'Authorization': `Bearer ${auth.token}`,
                // 'Access-Control-Allow-Origin': '*'
              }}
              onChange={onFileUploadChange}
            >
              <Button icon={<UploadOutlined />}>Upload Lease (Max: 1)</Button>
            </Upload>
          </Col>
        </Row>

        <Divider />

        <Form.Item label="Deposit Details" style={{ marginBottom: 0 }}>
          <Form.Item
            name={'security_deposit_amount'}
            rules={[{required: true, message: 'Security Deposit Amount is required'}]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input placeholder={'Deposit Amount...'} />
          </Form.Item>
          <Form.Item
            name={'utility_deposit_amount'}
            rules={[{required: true, message: 'Utility Deposit Amount is required'}]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
          >
            <Input placeholder={'Utility Deposit Amount...'} />
          </Form.Item>
        </Form.Item>

        <Divider />

        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>

      </Form>

    </React.Fragment>
  )
}

const ConrtactFormPage = () => {
  return (
    <AppLayout>
      <LeaseForm />
    </AppLayout>
  )
}

export default ConrtactFormPage


