import React, { useEffect, useState } from 'react'
import AppLayout from '../../layouts/main.layout'
import DeleteButton from '../../components/DeleteButton'
import {
  Button,
  Dropdown,
  Menu,
  PageHeader,
  Row,
  Col,
  Spin,
  Divider,
//  notification
} from "antd";
import {Link} from "react-router-dom";
import {
  PlusOutlined
} from '@ant-design/icons';
import {
  useParams
} from 'react-router-dom'
import api from '../../lib/api'
import PropertyDetailCard from './components/PropertyDetailCard'
import TenantDetailCard from './components/TenantDetailCard'
import DepositNotesCard from './components/DepositNotesCard'
import RentNotes from './components/RentNotes'

const menu = (
  <Menu>
    <Menu.Item>
      <Link to={'properties/add_new/apartment'} key={'1'}>Apartment</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={'properties/add_new/room'} key={'1'}>Room</Link>
    </Menu.Item>
  </Menu>
);

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

function Lease () {

  let { id } = useParams()
  const [lease, setLease] = useState(null)

  useEffect(() => {
    console.log(`Lease ID: ${id} is loading`)

    api.get('/api/lease/show/'+id)
    .then(response => {
      console.log(response)
      setLease(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })

  }, [id])

  if(!lease) {
    return (
      <div style={{
        margin: '20px 0',
        marginBotom: '20px',
        padding: '30px 50px',
        textAlign: 'center',
      }}>
        <Spin />
      </div>
    )
  }

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title={`Lease: ${lease.lease_number}`}
        subTitle="Lease Details"
        extra={[
          // <Dropdown overlay={menu} placement="bottomRight" key={1}>
          //   <Button icon={<PlusOutlined />} />
          // </Dropdown>,
        ]}
      />
      <Divider />
      <h3>Lease Details</h3>
      <Row gutter={8}>
        <Col span={6}>
          <DescriptionItem title={'Lease #'} content={lease.lease_number} />
        </Col>
        <Col span={4}>
          <DescriptionItem title={'Num Pax'} content={lease.num_pax} />
        </Col>
        <Col span={11}>
          <DescriptionItem title={'Date Range'} content={`${lease.start_date} to ${lease.end_date}`} />
        </Col>

      </Row>
      <Row gutter={8}>
        <Col span={6}>
          <DescriptionItem title={'Rent Amount'} content={lease.rent_amount} />
        </Col>
        <Col span={4}>
          <DescriptionItem title={'Rent due by'} content={lease.grace_period_days} />
        </Col>
        <Col span={4}>
          <DescriptionItem title={'Fine Rate'} content={lease.fine_rate} />
        </Col>
        <Col span={10}>
          <DescriptionItem title={'Lease File'} content={'File Name Goes here'} />
        </Col>
      </Row>

      <Divider />

      <Row gutter={8}>
        <Col span={8}>
          <PropertyDetailCard property={lease.property} />
        </Col>
        <Col span={8}>
          <TenantDetailCard tenant={lease.contact} />
        </Col>
        <Col span={8}>
          <DepositNotesCard
            leaseId={lease.id}
          />
        </Col>
      </Row>
      <Divider />
      <DeleteButton
        text={'Delete'}
        objectType={'Lease'}
        url={'api/lease/delete/'+lease.id}
        deleteSuccessTitle={'Deleted'}
        deleteSuccessMessage={'Lease deleted successfully'}
        redirectTo={'/leases'}
      />
      <Divider />

      <h3>Rent Payments</h3>
      <RentNotes leaseId={lease.id}/>
    </React.Fragment>
  )
}

const LeasePage = () => (
  <AppLayout>
    <Lease />
  </AppLayout>
)

export default LeasePage