export const UIConst = {
    PaymentStatus: {
        Paid: { id:3, slug:'paid'},
        Partial: { id:2, slug:'partial'},
        Unpaid: { id:1, slug:'unpaid'}
    },
    TransactionType: {
        Deposit: { id:5010, slug:'deposit-receipt'},
        DepositRefund: { id:5020, slug:'deposit-refund'},
        RentReceipt: { id:5030, slug:'rent-receipt'},
        PaymentReceipt: { id:5031, slug:'payment-receipt'},
        RentRefund: { id:5040, slug:'rent-refund'},
        OutwardPayment: { id:5041, slug:'outward-payment'},
        NonCashExpense: { id:5042, slug:'non-cash-expense'}
    },
    TrxTypeOption:{
        SecurityDeposit: { id: 5001, slug: 'security-deposit'},
        UtilityDeposit: { id: 5002, slug: 'utility-deposit'},
        Rent: { id: 5003, slug: 'rent'},
    },
    Currency:{
        MVR: {id:1, slug: 'mvr'},
        USD: {id:2, slug: 'usd'}
    },
    ContactType:{
        Tenant: {id:1, slug: 'tenant'},
        Professional: {id:2, slug: 'professional'}
    }

}


/*
abstract class SystemTransactionType{
    const Deposit = ['slug' => 'deposit-receipt', 'id' => 5010];
    const DepositRefund = ['slug' => 'deposit-refund', 'id' => 5020];

    const RentReceipt = ['slug' => 'rent-receipt', 'id' => 5030];
    const PaymentReceipt = ['slug' => 'payment-receipt', 'id' => 5031];
    const RentRefund = ['slug' => 'rent-refund', 'id' => 5040];
    const OutwardPayment = ['slug' => 'outward-payment', 'id' => 5041];
    const NonCashExpense = ['slug' => 'non-cash-expense', 'id' => 5042];
}

abstract class SystemTrxTypeOption{
    const SecurityDeposit = ['slug' => 'security-deposit', 'id' => 5001];
    const UtilityDeposit = ['slug' => 'utility-deposit', 'id' => 5002];
    const Rent = ['slug' => 'rent', 'id' => 5003];

}

*/