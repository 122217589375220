import React, { useState } from 'react'
import { Layout } from 'antd';
import Sidebar from '../components/Sidebar'
import SidebarLogo from '../components/SidebarLogo'
import AppHeader from '../components/Header'

const { Content, Footer, Sider } = Layout;

// const headerStyles = {
//   padding: '0',
//   backgroundColor: '#fff'
// }

const contextBoxStyles = {
  backgroundColor: '#fff',
  margin: '24px 16px',
  padding: 24
}

// const triggerStyles = {
//   fontSize: '18px',
//   lineHeight: '64px',
//   padding: '0 24px',
//   cursor: 'pointer',
//   transition: 'color 0.3s'
// }

function MainLayout ({ children }) {

  /**
   * @todo: refactor toggle collapse with custom Hook to handle toggles
   */
  const [collapsed, setCollapse] = useState(false)

  const onCollapse = collapsed => {
    console.log(collapsed);
    setCollapse(collapsed)
  };

  const toggle = () => {
    setCollapse(!collapsed)
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <SidebarLogo collapsed={collapsed}/>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <AppHeader toggle={toggle} collapsed={collapsed} />
        <Content style={contextBoxStyles}>
          <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>RMS ©2021 by Benlever</Footer>
      </Layout>
    </Layout>
  );

}

export default MainLayout