import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  Divider
} from 'antd'
import api from '../../../lib/api'

function BuildingForm (props) {
  const [form] = Form.useForm()
  const { Option } = Select
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [isStateDisabled, setStateDisabled] = useState(true)

  /*
   * Fetching all the countries from the API
   */
  useEffect(() => {
    api.get('/api/geo-data/country/index')
    .then(response => {
      setCountries(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [])

  /*
   * Form Styling
   */
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  }

  const onFinish = (values) => {
    console.log('Building Form Success:', values)

    api.post('/api/building/store', values)
    .then(response => {
      console.log('Building ID', response.data.data.id)
      props.setBuildingId(response.data.data.id)
      props.setSelectedBuilding(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Building Form Failed:', errorInfo);
  };

  const onCountrySelect = (value) => {

    let countryIndex = countries.findIndex(country => country.id === value)

    let stateOptions = countries[countryIndex].states

    setStates(stateOptions)
    setStateDisabled(false)
    //setSelectedState(stateOptions[0].id)
    form.setFieldsValue({
      state_id: (stateOptions.length > 0) ? stateOptions[0].id : null,
      city_id: null
    })
  }

  const onStateSelect = (value) => {
    //console.log(value)
    api.get('api/geo-data/state/'+value+'/city/index')
    .then(response => {
      console.log(response)

      setCities(response.data.data)
      form.setFieldsValue({city_id: response.data.data[0].id})
    })
    .catch(error => {
      console.log(error)
    })
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      layout={'horizontal'}
      labelAlign={'right'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={'Building Name'}
        name={'building_name'}
        rules={[{required: true, message: 'Building Name is required'}]}
      >
        <Input placeholder={'Building Name or Building Number'} />
      </Form.Item>

      <Form.Item
        label={'Address Line 1'}
        name={'address_line_1'}
        rules={[{required: true, message: 'Addresss Line 1 is required'}]}
        >
        <Input placeholder={'Address Line 1'} />
      </Form.Item>

      <Form.Item
        label={'Address Line 2'}
        name={'address_line_2'}
        rules={[{required: false, message: 'Addresss Line 2 is required'}]}
      >
        <Input placeholder={'Address Line 2'} />
      </Form.Item>

      <Form.Item
        label={'Address Line 3'}
        name={'address_line_3'}
        rules={[{required: false, message: 'Addresss Line 3 is required'}]}
      >
        <Input placeholder={'Address Line 3'} />
      </Form.Item>

      <Form.Item
        label={'Post Code'}
        name={'post_code'}
        rules={[{required: false, message: 'Post Code is required'}]}
      >
        <Input placeholder={'Post Code'} />
      </Form.Item>


      <Form.Item label={'Country'} name={'country_id'} rules={[{required: true, message: 'Country is required'}]}>
        <Select
          showSearch
          optionFilterProp="children"
          onSelect={onCountrySelect}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {countries.map(country => {
            return (
            <Option key={country.id} value={country.id}>{country.name}</Option>
            )
          })}

        </Select>
      </Form.Item>

      <Form.Item label={'State'} name={'state_id'} rules={[{required: true, message: 'State is required'}]}>
        <Select
          showSearch
          disabled={isStateDisabled}
          onSelect={onStateSelect}
          placeholder={'Select a Country first...'}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {states.map(state => (
            <Option key={state.id} value={state.id}>{state.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={'City'} name={'city_id'} rules={[{required: true, message: 'City is required'}]}>
        <Select
          showSearch
          disabled={isStateDisabled}
          placeholder={'Select city...'}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {cities.map(city => (
            <Option key={city.id} value={city.id}>{city.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label=" " colon={false}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>

      <Divider />

    </Form>
  )
}

export default BuildingForm