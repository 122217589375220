import React, { useEffect, useState } from 'react'
import useToggleState from '../../../hooks/useToggleState'
import {
  Select,
  Space,
  Row,
  Col,
  Button
} from 'antd'
import api from '../../../lib/api'
import SelectedBuilding from './SelectedBuilding'
import BuildingForm from './BuildingForm'

const { Option } = Select;


const SearchBuilding = (props) => {

  const { buildings } = props

  return (
      <React.Fragment>

          <Row>
            <Col span={4} style={{textAlign: 'right'}} className={'ant-form-item-label'}>
              <label>Search</label>
            </Col>
            <Col span={14}>
              <Space direction={'vertical'} style={{width: '100%'}}>
                <Select
                  style={{width: '100%'}}
                  //value={() => console.log('Building Value')}
                  showSearch
                  optionFilterProp="children"
                  onSelect={props.onBuildingSelect}
                  placeholder={'Search..'}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {buildings.map(building => (
                    <Option key={building.id} value={building.id}>{building.name}</Option>
                  ))}
                </Select>

              </Space>
            </Col>
          </Row>

      </React.Fragment>
  )
}

function SelectBuilding (props) {

  const [isSearching, setSearching] = useToggleState(true)
  const [buildings, setBuildings] = useState([])

  useEffect(() => {
    api.get('api/building/index')
    .then(response => {
      setBuildings(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [])


  const OnBuildingSelect = (value) => {
    let building = buildings.find(building => building.id === value)

    props.setSelectedBuilding(building)
    props.setBuildingId(building.id)
  }

  const TogglePromptText = () => {
    return (
      <React.Fragment>
        <Row>
          <Col span={4}>
          </Col>
          <Col span={14} style={{marginTop: '10px'}}>
            {(isSearching) ? (
              <p>Couldn't find the Building? <Button style={{color: "#0984e3", hover: 'pointer'}} onClick={setSearching}>create one!</Button></p>
            ) : (
              <p>Already created? <Button style={{color: "#0984e3"}} onClick={setSearching}>search for it!</Button></p>
            )}
          </Col>
        </Row>
      </React.Fragment>
    )

  }

  return (
    <React.Fragment>
      {(!props.isEditing) ? (
        <div>
          {(isSearching) ? (
        <SearchBuilding
          buildings={buildings}
          setSearching={setSearching}
          onBuildingSelect={OnBuildingSelect}
        />
      ): (
        <BuildingForm
          setBuildingId={props.setBuildingId}
          setSelectedBuilding={props.setSelectedBuilding}
        />
      )}
        <TogglePromptText />
        </div>
      ) : (
        <div></div>
      )}

      {(props.selectedBuilding === null) ? (
        <Row>
          <Col span={4}>
          </Col>
          <Col span={14}>
            <div style={{
              border: '1px solid #b2bec3',
              backgroundColor: '#dfe6e9',
              padding: '10px',
              color: '#636e72'
            }}>
              No Building Selected
            </div>
          </Col>
        </Row>
      ) : (
        <SelectedBuilding building={props.selectedBuilding}/>
      )}

    </React.Fragment>
  )

}

export default SelectBuilding