import React, {useEffect, useState} from 'react'
import {
  Table,
  Tag,
} from 'antd'
import IncomeNoteModal from './IncomeNoteModal'
import IncomeNotesDrawer from './IncomeNotesDrawer'
import api from '../../../lib/api'
import { UIConst } from '../../../constants/UIConst'
import PaidStatusTag from '../../../components/PaidStatusTag'


export default  function RentNotes (props) {
  const [rentNotes, setRentNotes] = useState([])
  const {leaseId} = props

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id"
    // },
    {
      title: "Bill Date",
      dataIndex: "effective_date",
      key: "effectiveDate"
    },
    {
      title: 'Due',
      dataIndex: "due_date_human",
      key: "due_date_human"
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount"
    },
    {
      title: "Status",
      // dataIndex: "payment_status_id",
      key: "paymentStatus",
      render: (r) => <PaidStatusTag paidStatus={r.payment_status_id} isOverdue={r.is_overdue}/>
    },
    {
      title: "Action",
      dataIndex: "",
      render: (record) => {
        let balanceAmount = record.amount - record.paid_sum

        if(record.income_note_count === 0) {
          return (
            <IncomeNoteModal
              btnText={'Receive'}
              titleText={`Confirm Payment for Rent Note ID ${record.id}`}
              leaseId={record.lease_id}
              amount={balanceAmount}
              transactionId={record.id}
              receivableType={'rent-note'}
              updateNote={updateNoteRecord}
            />
          )
        } else {
          return (
            <IncomeNotesDrawer
              transactionId={record.id}
              receivableType={'rent-note'}
              title={`Received for Rent Note ID: ${record.id}`}
              leaseId={record.lease_id}
              amount={record.amount}
              balanceAmount={balanceAmount}
              updateNote={updateNoteRecord}
            />
          )
        }
      }
    }
  ];

  const updateNoteRecord = (id, data) => {
    console.log('data', data)
    //console.log(`updating Rent Note ${id}`)
    const updatedNotes = [...rentNotes]
    const noteIndex = updatedNotes.findIndex((note) => {
      return note.id === data.id
    })

    console.log(data)

    console.log('note ID', data.id)
    console.log('note index', noteIndex)
    updatedNotes[noteIndex] = data.receivable

    setRentNotes(updatedNotes)
  }

  let url = 'api/rent-note/index'

  if(leaseId) {
    url = `api/lease/${leaseId}/rent-note/index`
  }

  useEffect(() => {
    api.get(url)
    .then(response => {
      console.log(response)
      setRentNotes(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [url])

  return (
    <React.Fragment>
      <Table columns={columns} dataSource={rentNotes} rowKey={(record) => record.id}/>
    </React.Fragment>
  )

}

