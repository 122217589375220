import React, { useState } from 'react'
import { Drawer, Button, Table, Dropdown, Menu, Popconfirm } from 'antd'
import { DownOutlined } from '@ant-design/icons';

import api from '../../../lib/api'
import IncomeNoteModal from './IncomeNoteModal'

export default function IncomeNotesDrawer (props) {
  const [paidNotes, setIncomeNotes] = useState([])
  const [visible, setVisible] = useState(false)
  let title = 'Income Notes'

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'Received On',
      dataIndex: 'received_on',
      key: 'received_on'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Ref',
      dataIndex: 'reference',
      key: 'reference'
    },
    {
      title: '',
      render: (record) => {
        return (
          <>
            <Dropdown overlay={() => {
              return (
                <Menu>
                  <Menu.Item key="1" >
                    <IncomeNoteModal
                      btnText={'Edit'}
                      titleText={`Edit Payment ID: ${record.id}`}
                      leaseId={record.lease_id}
                      transactionId={record.transaction_id}
                      receivableType={'rent-note'}
                      updateNote={props.updateNote}
                      isEditing={true}
                      editingId={record.id}
                      btnStyle={{width: '100%'}}
                    />
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Popconfirm placement="topLeft" title={'Confirm Deletion'} onConfirm={() => deleteIncomeNote(record.id)} okText="Yes" cancelText="No">
                      <Button style={{width: '100%'}}>Delete</Button>
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              )
            }}>
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </>
        )
      }
    }
  ]

  const deleteIncomeNote = (id) => {
    console.log(`Deleting Income Note ${id}`)
    api.delete(`/api/paid-note/delete/${id}`)
    .then(response => {
      console.log(response)

      const {data} = response.data
      console.log(data)

      props.updateNote(data.id, data)
      fetchData()
    })
    .catch(error => {
      console.log(error)
    })
  }

  if(props.title) {
    title = props.title
  }

  const onClose = () => {
    setVisible(false);
  }

  const fetchData = () => {
    api.get(`/api/paid-note/index/${props.transactionId}/${props.receivableType}`)
    .then(response => {
      console.log(response)
      setIncomeNotes(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  const showDrawer = () => {
    setVisible(true);

    fetchData()
  }

  return (
    <>
      <Button onClick={showDrawer}>
        View
      </Button>
      <Drawer
        title={title}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={720}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <IncomeNoteModal
              btnText={'Create an Income Note'}
              titleText={`Confirm Payment for Rent Note ID ${props.transactionId}`}
              leaseId={props.leaseId}
              transactionId={props.transactionId}
              amount={props.balanceAmount}
              receivableType={'rent-note'}
              updateNote={props.updateNote}
              fetchDrawerData={fetchData}
            />
          </div>
        }
      >
        <Table size="small" columns={columns} dataSource={paidNotes} rowKey={(record) => record.id}/>
      </Drawer>
    </>
  )
}