import React from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Divider
} from 'antd'
import api from '../../../lib/api'
import { useCallback, useState } from 'react'
import { useEffect } from 'react'
import moment from "moment";
import DeleteButton from '../../../components/DeleteButton'


function TenantForm (props) {
  const { setTenant, toggleCreateFormDisplay, onClose } = props
  const [form] = Form.useForm()
  const { Option } = Select
  const [isSaveInProgress, setSaveInProgress] = useState(false)
  const [isEditing, setEditing] = useState(false)

  const setTenantFormValues = useCallback((data) => {
    form.setFieldsValue({
      name: data.name,
      identification: data.identification,
      identification_type: data.identification_type,
      email: data.email,
      cell_phone_no: data.cell_phone_no,
      dob: moment(data.dob, 'YYYY-MM-DD H:m:s'),
      occupation: data.occupation,
      marital_status: data.marital_status
    })
  }, [form])

  const onDelete = () =>{
    props.onDelete(props.tenantForEdit)
  }

  const onFinish = (values) => {
    console.log('Validation Success', values)
    setSaveInProgress(true)

    if(isEditing){
      let id = props.tenantForEdit.id

      api.post(`/api/tenant/update/${id}`, values)
      .then(response => {
        console.log(response)
        setSaveInProgress(false)
        props.tenantUpdated(response.data.data)
        onClose()
        
      })
      .catch(error => {
        console.log(error)
        setSaveInProgress(false)
      })
    }else{
      api.post('/api/tenant/store', values)
      .then(response => {
        console.log(response)
        setTenant(response.data.data)
        setSaveInProgress(false)
        toggleCreateFormDisplay()
        onClose()
        
      })
      .catch(error => {
        console.log(error)
        setSaveInProgress(false)
      })
    }
    

  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed: ', errorInfo)
  }

  useEffect(() => {
    if(props.tenantForEdit != null){
      setTenantFormValues(props.tenantForEdit)
      setEditing(true)
  
    }else{
      form.resetFields()
      setEditing(false)
    }
  }, [props.tenantForEdit, setTenantFormValues])
  


  return (
    <React.Fragment>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        hideRequiredMark
      >
        <h3>Tenant Details</h3>

        <Form.Item label={'Name'} name={'name'} rules={[{ required: true, message: 'Name is required'}]}>
          <Input placeholder={'Tenant Name...'} />
        </Form.Item>

        <Form.Item label="Identification" style={{ marginBottom: 0 }}>
          <Form.Item
            name="identification"
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input placeholder="Identification Number..." />
          </Form.Item>
          <Form.Item
            name="identification_type"
            rules={[{ required: true }]}

            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
          >
            <Select
              placeholder={'Indentification Type'}
            >
              <Option key={1} value={'passport'}>Passport</Option>
              <Option key={2} value={'nic'}>NIC</Option>
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Contact" style={{ marginBottom: 0 }}>
          <Form.Item
            name="email"
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input placeholder="Email..." />
          </Form.Item>
          <Form.Item
            name="cell_phone_no"
            rules={[{ required: true }]}

            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
          >
            <Input placeholder="Cell Phone Number..." />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={'Occputation'}
          name="occupation"
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input placeholder="Occupation..." />
        </Form.Item>
        <Form.Item
          label={'Date of Birth'}
          name="dob"
          rules={[{ required: true }]}

          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0px 0px 8px' }}
        >
          <DatePicker style={{width: '100%'}}/>
        </Form.Item>

        <Form.Item
          name="marital_status"
          rules={[{ required: true }]}
          label={'Marital Status'}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0px 24px 0px' }}
        >
          <Select
            placeholder={'Marital Status'}
          >
            <Option key={1} value={'married'}>Married</Option>
            <Option key={2} value={'divorce'}>Divorced</Option>
            <Option key={3} value={'single'}>Single</Option>
            <Option key={4} value={'widowed'}>Widowed</Option>
          </Select>
        </Form.Item>

        <Divider style={{marginTop: '0px'}}/>
        {(isEditing) ? (
          <Form.Item>
          <Button disabled={isSaveInProgress} type="primary" htmlType="submit">Update</Button>
          <DeleteButton
            text={'Delete'}
            objectType={'Tenant'}
            // url={'api/tenant/delete/'+props.tenantForEdit.id}
            deleteSuccessTitle={'Deleted'}
            deleteSuccessMessage={'Tenant deleted successfully'}
            redirectTo={'/tenants'}
            onDeleteAction={onDelete}
          />
        </Form.Item>
        ) : (
          <Form.Item>
          <Button disabled={isSaveInProgress} type="primary" htmlType="submit">Create</Button>
          <Button danger onClick={toggleCreateFormDisplay}>Cancel</Button>
        </Form.Item>
        )}
        

      </Form>

    </React.Fragment>
  )
}

export default TenantForm