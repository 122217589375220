import React from 'react'
//import '../App.css'

const DefaultPage = (props) => {
  return (
    <header className="App-header">
      <div>
        {props.text}
      </div>
    </header>
  )
}

export default DefaultPage