import React from 'react'
import AppLayout from '../layouts/main.layout'

function Dashboard() {
  return (
    <div>
      <h1>Dashboard</h1>
    </div>
  )
}

const page = () => (
  <AppLayout>
    <Dashboard />
  </AppLayout>
)

export default page
