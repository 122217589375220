import React, {useEffect, useState} from 'react'
import AppLayout from '../../layouts/main.layout'
import {
  Table,
  PageHeader,
  Button
} from "antd";
import {
  Link
} from "react-router-dom";
import {
  PlusOutlined
} from '@ant-design/icons';
import api from '../../lib/api'
import TenantDrawer from './components/TenantDrawer';
import useToggle from '../../hooks/useToggleState';





function Tenants () {


  const [tenants, setTenants] = useState([])
  const [drawerVisible, toggleDrawerVisible] = useToggle(false)
  const [selectedTenantIndex, setSelectedTenantIndex] = useState(null)
  const [selectedTenant, setSelectedTenant] = useState(null)

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Button type="link" onClick={()=> onTenantClick(record.id)}>{text}</Button>
    },
    {
      title: 'Identification',
      dataIndex: 'identification',
      key: 'identification'
    },
    {
      title: 'Cell Phone',
      dataIndex: 'cell_phone_no',
      key: 'cell_phone_no'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    }
  ];

  const onTenantClick =(value) => {
    let tenant = tenants.find( tenant => tenant.id == value)
    setSelectedTenant(tenant)
    setSelectedTenantIndex(value)
    toggleDrawerVisible()

  }

  const onTenantUpdate = (tenant) => {
    console.log(tenant)
    let tenantsTemp = [...tenants]
    let index = tenants.findIndex(t => t.id == tenant.id)
    
    tenantsTemp[index] = tenant
    console.log(`index: ${index}`)
    setTenants(tenantsTemp)
    console.log(tenants)
  }

  const onTenantCreate = (tenant) => {
    console.log(tenant)
    let tenantsTemp = [...tenants]
    tenantsTemp.push(tenant)
    setTenants(tenantsTemp)

  }

  const onTenantDelete = (tenant) => {
    toggleDrawerVisible()
    console.log(tenant)
    let tenantsTemp = [...tenants]
    tenantsTemp = tenantsTemp.filter(t => t.id != tenant.id)
    
    setTenants(tenantsTemp)

  }

  const onClose = () => {
    toggleDrawerVisible()

  }

  useEffect(() => {

    api.get('/api/tenant/index')
    .then(response => {
      setTenants(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })

    return () =>  {
      setTenants([])
    }
  }, [])

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Tenants"
        subTitle="Listing of all tenants"
        extra={[
          <Link to={'#'} onClick={() => {toggleDrawerVisible(); setSelectedTenant(null)}} key={1}>
            <Button icon={<PlusOutlined />} />
          </Link>,
        ]}
      />
      <Table columns={columns} dataSource={tenants} rowKey={(record) => record.id}/>

      <TenantDrawer
        onClose={onClose}
        drawerVisible={drawerVisible}
        toggleDrawerVisible={toggleDrawerVisible}
        tenantForEdit={selectedTenant}
        tenantUpdated={onTenantUpdate}
        setTenant={onTenantCreate}
        onDelete={onTenantDelete}
        // toggleError={toggleError}
      />

    </React.Fragment>
  )
}

const TenantsPage = () => (
  <AppLayout>
    <Tenants />
  </AppLayout>
)

export default TenantsPage