import React from 'react'
import {
  //Button,
  //Dropdown,
  //Menu,
  //PageHeader,
  //List,
  //Divider,
  Row,
  Col,
  //Modal
} from 'antd';

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

function Apartment (props) {
  const { apartment } = props

  return (
    <React.Fragment>
      {/* <Row>
        <Col span={12}>
          <DescriptionItem title={'Name'} content={apartment.name} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={'Identifier'} content={apartment.identifier.toUpperCase()} />
        </Col>
      </Row> */}
      <Row>
        <Col span={12}>
          <DescriptionItem title={'Type'} content={apartment.apartment_type.name} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={'SQM'} content={apartment.sqm} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title={'Bedrooms'} content={apartment.num_bed_rooms} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={'Bathrooms'} content={apartment.num_bath_rooms} />
        </Col>
      </Row>
      
    </React.Fragment>
  )
}

export default Apartment