import React from 'react'

import { Tag } from 'antd'
import { UIConst } from '../constants/UIConst'


const getTagColor = (paidStatus, isOverdue) =>{

    const statuses = UIConst.PaymentStatus

    if(paidStatus != statuses.Paid.id && isOverdue){
        return 'red'
    }

    
    switch(paidStatus){
        case statuses.Paid.id:
            return 'green'
        case statuses.Partial.id:
            return 'purple'
        case statuses.Unpaid.id:
            return 'orange'
        //TODO: overdue payments as red
    }
}

const getTagName = (paidStatus, isOverdue) =>{

    const statuses = UIConst.PaymentStatus

    switch(paidStatus){
        case statuses.Paid.id:
            return 'PAID'
        case statuses.Partial.id:
            return 'PARTIAL'
        case statuses.Unpaid.id:
            if(isOverdue){
                return 'UNPAID'
            }else{
                return 'UNPAID'
            }
            
    }
}


function PaidStatusTag(props){

    let tagColor = getTagColor(props.paidStatus, props.isOverdue)
    let tagName = getTagName(props.paidStatus, props.isOverdue)

    return (
        <Tag color={tagColor}>{tagName}</Tag>
    )
}


export default PaidStatusTag