import React from 'react'

function SidebarLogo (props) {

  const logoStyles  = {
    height: '32px',
    margin: '16px',
    background: 'rgba(255, 255, 255, 0.3)',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    transition: 'color 0.3s',
    overflow: 'hidden'
  }

  const textStyles = {
    overflow: 'hidden',
    padding: 0,
    margin: 0,
    whiteSpace: 'nowrap'
}

  return (
    <div className="logo" style={logoStyles}>
      {props.collapsed ? (
        <p style={textStyles}>RMS</p>
      ) : (
        <p style={textStyles}>RMS - Portal</p>
      )}
    </div>
  )
}

export default SidebarLogo