const config = {
  // baseURL: 'http://rental-mgt-webapi.test',
  baseURL: 'https://api.rms.benlever.com',
}

export const clientCredentials = {
  client_secret: 'pKa6ISPVejNR3pAgURWatjrtTzNwdvLHfCxPMioG',
  client_id: '1',
  grant_type: 'password'
}

export default config

