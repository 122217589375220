import React from 'react'
import { Menu } from 'antd'
import {
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  ContainerOutlined,
  SettingOutlined,
  AuditOutlined
} from '@ant-design/icons';
import {
  Link,
  useLocation
} from "react-router-dom";

function Sidebar () {
  //const { SubMenu } = Menu;

  let location = useLocation();

  return (
    <Menu theme="dark" defaultSelectedKeys={[location.pathname]} mode="inline">
      <Menu.Item key="/dashboard" icon={<PieChartOutlined />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="/properties" icon={<ContainerOutlined />}>
        <Link to="/properties">Properties</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<AuditOutlined />}>
        <Link to="/leases">Leases</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined />}>
        <Link to="/tenants">Tenants</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<SettingOutlined/>}>
        <Link to="/transactions">Transactions</Link>
      </Menu.Item>
      {/*<SubMenu key="sub1" icon={<UserOutlined />} title="Users">*/}
      {/*  <Menu.Item key="3">Tom</Menu.Item>*/}
      {/*  <Menu.Item key="4">Bill</Menu.Item>*/}
      {/*  <Menu.Item key="5">Alex</Menu.Item>*/}
      {/*</SubMenu>*/}
      <Menu.Item key="6" icon={<TeamOutlined />} >
        Staff
      </Menu.Item>
      {/*<SubMenu key="sub2" icon={<TeamOutlined />} title="Team">*/}
      {/*  <Menu.Item key="6">Team 1</Menu.Item>*/}
      {/*  <Menu.Item key="8">Team 2</Menu.Item>*/}
      {/*</SubMenu>*/}
      <Menu.Item key="7" icon={<SettingOutlined />}>
        Settings
      </Menu.Item>
    </Menu>
  )
}

export default Sidebar