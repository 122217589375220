import React, { useEffect, useState} from 'react'
import {
  Card,
  Tag
} from 'antd'
import IncomeNoteModal from './IncomeNoteModal'
import api from '../../../lib/api'
import { UIConst } from '../../../constants/UIConst'
import PaidStatusTag from '../../../components/PaidStatusTag'

const DepositNoteCard = (props) => {

  const { note } = props

  let title = ''

  if(note.trx_type_option_id === UIConst.TrxTypeOption.SecurityDeposit.id) {
    title = 'Security Deposit'
  } else {
    title = 'Utility Deposit'
  }

  let balanceAmount = note.amount - note.paid_sum;

  return (
    <div key={note.id} className={'deposit-note-card'}>
      <h3>{title}</h3>
      <p>Amount: {Number(note.amount).toFixed(2)}</p>
      
      
        
      <PaidStatusTag paidStatus={note.payment_status_id}/>
      <p>Balance: {(balanceAmount).toFixed(2)}</p>
      <br/>
      {(note.payment_status_id !== UIConst.PaymentStatus.Paid.id) &&
           <IncomeNoteModal
            btnText={'Record payment'}
            titleText={`Confirm Payment for Deposit ID ${note.id}`}
            leaseId={note.lease_id}
            transactionId={note.id}
            amount={balanceAmount}
            receivableType={'deposit-note'}
            updateNote={() => console.log('update stuff')}
            refreshDepositNotes={props.fetchDepositNotes}
          />
        }
    </div>
  )
}

function DepositNotesCard (props) {
  const [internalDepositNotes, setDepositNotes] = useState([])

  const fetchDepositNotes = () => {
    api.get(`/api/deposit-note/index?lease_id=${props.leaseId}`)
    .then(response => {
      console.log(response)
      setDepositNotes(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
      fetchDepositNotes()
  }, [])

  return (
    <React.Fragment>
      <Card
        title={'Deposit Notes'}
      >
      {internalDepositNotes.map(note => (
        <DepositNoteCard
          key={note.id} note={note}
          fetchDepositNotes={fetchDepositNotes}
        />
      ))}
      </Card>
    </React.Fragment>
  )

}

export default DepositNotesCard