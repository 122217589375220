import React, {createContext, useEffect} from 'react'
import authReducer from '../reducers/auth.reducer'
import { useLocalStorageReducer } from "../hooks/useLocalStorageReducer";
import api from '../lib/api'

const initialState = {
  isAuthenticated: false,
  token: null,
  user: null,
  test: 'something'
}

export const AuthContext = createContext()
export const AuthDispatchContext = createContext()

export function AuthProvider (props) {

  const [auth, dispatch] = useLocalStorageReducer("auth", initialState, authReducer)

  api.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token


  useEffect(() => {
    api.get('/api/user')
    .then(response => {

    })
    .catch(error => {
      console.log(error)
      if(error.response && error.response.status === 401) {
        dispatch({type: 'CLEAR_TOKEN', data: 'Logged out'})
      }
    })
  }, [dispatch])

  return (
    <AuthContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  )
}