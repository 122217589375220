import React, { useContext } from "react";
import { Form, Input, Button, Divider } from "antd";
import { useHistory } from 'react-router-dom';
import WithLoginLayout from "../layouts/login.layout";
//import * as authHelper from '../helpers/auth.helper';
import { AuthDispatchContext} from "../contexts/auth.context";
import api from '../lib/api'
import { clientCredentials } from "../configs/api.config";



const LoginForm = () => {

  // const [loading, setLoading] = useState(false)

  const authDispatch = useContext(AuthDispatchContext)
  //const auth = useContext(AuthContext)
  const history = useHistory();

  const authenticate = (credentials) => {
    let tokenRequest = {...clientCredentials, username: credentials.email, password: credentials.password}

    api.post('/oauth/token', tokenRequest)
    .then(response => {
      //console.log(response.data)

      authDispatch({type: 'STORE_TOKEN', data: response.data})
      history.push('/dashboard')
    })
    .catch(error => {
      console.log(error)
    })

  }

  const onFinish = (values) => {
    console.log("Success:", values);
    authenticate(values)

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      layout={"vertical"}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input name={"email"} placeholder={"email"} autoComplete={"email"} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder={"password"}
          autoComplete={"current-password"}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

const Login = () => {
  return (
    <WithLoginLayout>
      <div>
        <h1 style={{ fontWeight: "bold", color: "#707C80" }}>Login</h1>
        <LoginForm />
      </div>
      <Divider />
      <div>
        <a href={"/register"}>Don't have an account? Sign Up!</a>
        <br />
        <a href={"/reset-password"}>Forgot your password?</a>
      </div>
    </WithLoginLayout>
  );
};

export default Login;
