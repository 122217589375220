import React, {useEffect, useState} from 'react'
import AppLayout from '../../layouts/main.layout'
import {
  Table,
  PageHeader,
  Button,
  Typography,
  Menu,
  Input,
  Space,
  Dropdown
} from "antd";
import {
  Link
} from "react-router-dom";
import {
  ExportOutlined,
  FilterOutlined,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined
} from '@ant-design/icons';
import api from '../../lib/api'
import PaidStatusTag from '../../components/PaidStatusTag';
import useToggle from '../../hooks/useToggleState';
import Avatar from 'antd/lib/avatar/avatar';
import { blue } from '@ant-design/colors';
import { UIConst } from '../../constants/UIConst';

const {Text} = Typography;
const {Search} = Input;



const inflowBtnMenu = (
  <Menu>
    <Menu.Item>
      <Link to={'transactions/add_new/general_income'} key="1">General Income</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={'transactions/add_new/property_income'} key="2">Property Income</Link>
    </Menu.Item>
    {/* TODO: Not implemented
      <Menu.Item>
      <Link to={'transactions/add_new/deposit'} key="3">Deposit</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={'transactions/add_new/credit'} key="4">Credits</Link>
    </Menu.Item> */}
  </Menu>
);

const outflowBtnMenu = (
  <Menu>
    <Menu.Item>
      <Link to={'transactions/add_new/general_expense'} key="1">General Expense</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={'transactions/add_new/property_expense'} key="2">Property Expense</Link>
    </Menu.Item>
    {/* TODO: Not implemented
      <Menu.Item>
      <Link to={'transactions/add_new/refund_deposit'} key="3">Return Deposit</Link>
    </Menu.Item> */}
    <Menu.Item>
      <Link to={'transactions/add_new/provision'} key="4">Provisions/ Depreciation</Link>
    </Menu.Item> 
  </Menu>
);

//generate search props


function Transactions () {

  const getFilterValues = dataIndex => {

    let filter = dataFilters.find( element => {
      
      return dataIndex === element.data_key
    })
    console.log(filter);

    return filter ? filter.values: []
  }

  

  const getColumnFilterProps = dataIndex => ({

    onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase()) : '',
    
    ellipsis: true,
    
    filters:[
      
      ... (getFilterValues(dataIndex).map(element => {
        return {
          text: element.name,
          value: element.id
        }
      }))
      ],
  })


  const renderDescPropertyCol = (record) => {
    let propertyUnit = "";
    let building = "";

    if(record.property){
      propertyUnit = record.property.name;
      if(record.property.building){
        building = record.property.building.name;
      }

      return (
        <>
          <Text>{record.trx_type_option.name}</Text>
          <br></br>
          <Text type="secondary">{`${building}, Unit: ${propertyUnit}`}</Text>
        </>
      )
    }else {
      return (
        <>
          <Text>{record.trx_type_option.name}</Text>
        </>
      )
    }

    
  }

  const renderAmountPaidyCol = (record) => {
    return (
      <>
        <Text>{`${record.currency} ${record.amount}`}</Text>
        <br></br>
        <Text type="secondary">{`${record.currency} ${record.paid_sum ? record.paid_sum : 0.00}`}</Text>
      </>
    )
  }

  const renderContactCol = (data) => {
    const contactName = data ? data.name : "Undefined User";
    return (
      <div>
        <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} /> <span>{contactName}</span>
      </div>
    )
  }

  const renderPaidStatusCol = (statusId, record, index) => {
    return (
      <PaidStatusTag paidStatus={record.payment_status_id} isOverdue={record.is_overdue}/>
    )
  }

  const renderActionCol = (id, record, index) => {

    return (
      <Link to={`transactions/view/${id}`}>Details</Link>
    )
  }
  const [transactions, setTransactions] = useState([])
  const [dataFilters, setDataFilters] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [drawerVisible, toggleDrawerVisible] = useToggle(false)
  const [selectedTenantIndex, setSelectedTenantIndex] = useState(null)
  const [selectedTenant, setSelectedTenant] = useState(null)

  const columns = [
    {
      title: 'BILL DATE',
      dataIndex: 'effective_date',
      key: 'effectiveDate'
    },
    {
      title: 'STATUS',
      key: 'payment_status_id',
      dataIndex: 'payment_status_id',
      filteredValue: activeFilters ? activeFilters.payment_status_id : null,
      render: renderPaidStatusCol,
        ...getColumnFilterProps('payment_status_id'),
    },
    {
      title: 'DESC / PROPERTY',
      key: 'building_id',
      render: renderDescPropertyCol,
      filteredValue: activeFilters ? activeFilters.building_id : null,
      ...getColumnFilterProps('building_id')

    },
    {
      title: 'CONTACT',
      dataIndex: 'contact',
      key: 'contact',
      render: renderContactCol
    },
    {
      title: 'AMOUNT / PAID',
      key: 'cell_phone_no',
      render: renderAmountPaidyCol
    },
    {
      title: 'ACTION',
      dataIndex: 'id',
      render: renderActionCol
    }
  ];


  const onClearFiltersClick = () => {
    setActiveFilters(null)
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log('Pagination/Filter/Sorter: ', pagination, filters, sorter);

    setActiveFilters(filters)
  }


  const onTenantClick =(value) => {
    let transaction = transactions.find( transaction => transaction.id == value)
    setSelectedTenant(transaction)
    setSelectedTenantIndex(value)
    toggleDrawerVisible()

  }

  const onTenantUpdate = (transaction) => {
    console.log(transaction)
    let transactionsTemp = [...transactions]
    let index = transactions.findIndex(t => t.id == transaction.id)
    
    transactionsTemp[index] = transaction
    console.log(`index: ${index}`)
    setTransactions(transactionsTemp)
    console.log(transactions)
  }

  const onTenantCreate = (transaction) => {
    console.log(transaction)
    let transactionsTemp = [...transactions]
    transactionsTemp.push(transaction)
    setTransactions(transactionsTemp)

  }

  const onTenantDelete = (transaction) => {
    toggleDrawerVisible()
    console.log(transaction)
    let transactionsTemp = [...transactions]
    transactionsTemp = transactionsTemp.filter(t => t.id != transaction.id)
    
    setTransactions(transactionsTemp)

  }

  const onClose = () => {
    toggleDrawerVisible()

  }

  const onSearch = (query) => {
    
  }

  useEffect(() => {

    api.get('/api/transaction/index')
    .then(response => {
      if(response.data){
        console.log(response.data)
        setTransactions(response.data.data)
      if(response.data.meta){
        setDataFilters(response.data.meta.filters)
        
      }
      }
    })
    .catch(error => {
      console.log(error)
    })

    return () =>  {
      setTransactions([])
      setDataFilters([])
    }
  }, [])

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Transactions"
        subTitle="Listing of all transactions"
        extra={[
          <Dropdown overlay={inflowBtnMenu} arrow placement="bottomRight" icon={<PlusOutlined/>}>
            <Button type="primary" shape="round" icon={<PlusOutlined/>}>Inflow</Button>
          </Dropdown>
          ,
          <Dropdown overlay={outflowBtnMenu} arrow placement="bottomRight" icon={<PlusOutlined/>}>
            <Button type="primary" shape="round" danger icon={<MinusOutlined/>}>Outflow</Button>
          </Dropdown>
          
          
        ]}
      />
      <div className="page-controls-top">
        <Space>
          <Search placeholder="type here to search" onSearch={onSearch}/>
          <Button type="dashed" icon={<ExportOutlined />} ghost>CSV</Button>  
          <Button type="dashed" icon={<FilterOutlined />} ghost onClick={onClearFiltersClick} >Clear Filters</Button>
        </Space>
      </div>
      <Table 
      className="table-striped-rows" 
      columns={columns} 
      dataSource={transactions} 
      rowKey={(record) => record.id}
      onChange={handleChange}/>

      

    </React.Fragment>
  )
}

const TransactionsPage = () => (
  <AppLayout>
    <Transactions />
  </AppLayout>
)

export default TransactionsPage