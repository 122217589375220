import React from 'react'
import {
  Card,
  Divider
} from 'antd'

function PropertyDetailCard (props) {
  const { Meta } = Card
  const { property } = props

  const address = (address) => {

    let value = '';

    if(address.address_line_1) {
      value += address.address_line_1
    }

    if(address.address_line_2) {
      value += ' ' + address.address_line_2
    }

    if(address.address_line_3) {
      value += ' ' + address.address_line_3
    }

    return value
  }


  return (
    <React.Fragment>
      <Card  title={'Property Details'} extra={<a href="!#">More</a>}>
        <Meta
          title={property.name}
          description={
            <div className={'tenant-detail-card'}>
              <p>Type: {property.property_type}</p>
              <p>Building: {property.building.name}</p>
            </div>
          }
        />
        <Divider />
        <Meta
          title="Address"
          description={address(property.building.address)}
        />
      </Card>
    </React.Fragment>
  )
}

export default PropertyDetailCard