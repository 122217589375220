
import React from 'react'
import { Form, Input, Button, Space, Typography, PageHeader, Select, DatePicker, Row, Col, Divider, TreeSelect, InputNumber, notification } from 'antd'
import { useEffect, useState } from 'react'
import api from '../../lib/api'
import moment from 'moment'
import { UIConst } from '../../constants/UIConst'
import { useHistory } from 'react-router'

const { Option } = Select 
const { TextArea } = Input
const { TreeNode } = TreeSelect


const dateFormat = 'YYYY/MM/DD'

export const FORM_TYPE = {
    IncomeProperty: 'income-property',
    IncomeGeneral: 'income-general',
    Deposit: 'deposit',
    Credit: 'credit',
    ExpenseGeneral: 'expense-general',
    ExpenseProperty: 'expense-property',
    Provisions: 'provisions'

}



function AddTransaction (props){
    //Props
    const { title, formType } = props

    //State
    const [showLeaseField, setShowLeaseField] = useState(false)
    const [showPropertyField, setShowPropertyField] = useState(false)
    const [showUnitField, setShowUnitField] = useState(false)
    const [showDueDaysField, setShowDueDaysField] = useState(true)
    const [showPayerField, setShowPayerField] = useState(true)

    const [categories, setCategories] = useState([])
    const [contacts, setContacts] = useState([])
    const [leases, setLeases] = useState([])
    const [properties, setProperties] = useState([])
    const [selectedContact, setSelectedContact] = useState(null)
    const [form] = Form.useForm()
    const history = useHistory()

    //Effects

    useEffect(() =>{
        if(formType == FORM_TYPE.Provisions){
            setShowDueDaysField(false)
            setShowPayerField(false)   
        }
    }, [])


    useEffect(() => {
        console.log('requesting...', `/api/trx_type_category/index?form_type=${formType}`)
        api.get(`/api/trx_type_category/index?form_type=${formType}`)
        .then(response => {
            const data = response.data ? response.data.data : []
            setCategories(data)
        })
        .catch(error => {
            console.log(error)
        })

        return () => {
            setCategories([])
        }
    }, [])


    useEffect(() => {

        api.get(`/api/contact/index`)
        .then(response => {
            const data = response.data ? response.data.data : []
            setContacts(data)
        })
        .catch(error => {
            console.log(error)
        })

        return () => {
            setContacts([])
        }
    }, [])

    useEffect(() => {

        if(!selectedContact) return

        api.get(`/api/contact/${selectedContact.id}/lease/index`)
        .then(response => {
            const data = response.data ? response.data.data : []
            setLeases(data)
        })
        .catch(error => {
            console.log(error)
        })

        return () => {
            setLeases([])
        }
    }, [selectedContact])


    //Render helpers
    

    //Helpers

    const displayErrors = (data) => {
        const { errors } = data;
    
        const key = `open${Date.now()}`;
    
        errors.forEach(err => {
          notification.error({
            message: 'Error',
            description:
              err,
            onClick: () => {
              console.log('Notification Clicked!');
            },
            key
          });
        })
      }


    //Event handlers

    const handleFinish = (values) => {
        console.log("form validated:", values)
        values.form_type = formType

        api.post('api/transaction/store', values)
        .then(response => {
            console.log(response)
            notification.open({
                message: 'success',
                description: 'Transaction created!'
            });
            history.push('/transactions')
        })
        .catch(error => {
            console.log(error.response)
            if(error.response.status ===422){
                displayErrors(error.response.data)
            }else{
                displayErrors({errors: ['Something went wrong. Could not complete action']})
            }
        })
    }

    const handleFinishFailed = () => {
        //todo
    }

    const onCategorySelect = () => {

    }

    const onContactSelect = (selected) => {
        let contact = contacts.find(element => element.id == selected)
        setSelectedContact(contact)

        if((formType == FORM_TYPE.IncomeProperty 
            || formType == FORM_TYPE.ExpenseProperty)
            && contact){
            if (contact.contact_type_slug == UIConst.ContactType.Tenant.slug){
                setShowLeaseField(true)
            }else{
                setShowPropertyField(true)
            }
        }
    }



    return (
        <React.Fragment>
            <PageHeader
                className="site-page-header"
                onBack={() => history.goBack()}
                title={title}
            />
                <Form 
                    name="add-transaction" 
                    onFinish={handleFinish} 
                    onFinishFailed={handleFinishFailed}
                    labelCol={{ span: 8 }} 
                    wrapperCol={{ span: 16 }} 
                    style={{padding: '100px'}}
                    form={form}
                >
                    <Form.Item label="Category/Sub-category" name="trx_type_category" colon={false}
                    rules={[{ required:true, message: "Select a Category"}]}>
                        <TreeSelect 
                            size="large"
                            placeholder="Select category"
                            showSearch
                            onSelect={onCategorySelect}
                            treeDefaultExpandAll
                            filterTreeNode={(search, item) => {
                                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                            }}
                        >
                            {categories ? categories.map(category => (
                                <TreeNode disabled key={`C${category.id}`} value={`C${category.id}`} title={category.name}>
                                    {
                                        category.trx_type_options ? category.trx_type_options.map(trx_type_option => (
                                            <TreeNode key={trx_type_option.id} value={trx_type_option.id} title={trx_type_option.name}/>
                                        )):null
                                    }
                                </TreeNode>
                            )) : null}
                        </TreeSelect>
                    </Form.Item>

                    
                    <Form.Item label="Bill Date" colon={false}>
                    <Row gutter={24 }>
                        <Col span={8}>
                        <Form.Item name="date" colon={false}
                            initialValue={moment(new Date())}
                            rules={[{required:true, message: "Bill date required"}]}>
                                 <DatePicker size="large" format={dateFormat}/>                                 
                        </Form.Item>
                        </Col>
                        <Col span={16}>
                        <Form.Item  label="Amount (MVR)" name="amount" colon={false}
                            initialValue={0.0}
                            rules={[
                                {required:true, message: "Amount required"},
                                ]}>
                                <InputNumber 
                                    size="large" 
                                    style={{width: '100%'}} />
                        </Form.Item>
                        </Col>
                    </Row>

                    </Form.Item>
                    <Form.Item  label="Due in (days)" name="due_in" colon={false}
                            hidden={!showDueDaysField}
                            initialValue={0}
                            
                            rules={[
                                {required:showDueDaysField, message: "Due in days required"},
                                ]}>
                                <InputNumber size="large" placeholder="Amount" style={{width: '100%'}} min={0} style={{width: "200px"}}/>
                        </Form.Item>

                    <Form.Item label="Payer/Payee" name="contact" colon={false} hidden={!showPayerField}
                        rules={[{ required:showPayerField, message: "Select a Payer/Payee"}]}>
                            <Select placeholder="Select a contact" size="large" onSelect={onContactSelect}>
                                {contacts ? contacts.map(contact => (<Option key={contact.id} value={contact.id}> {contact.name} <sup>{`(${contact.contact_type_name})`}</sup></Option>) ) : null}
                            </Select>
                    </Form.Item>

                    <Form.Item label="Lease" name="lease" colon={false} hidden={!showLeaseField}
                        rules={[{ required:showLeaseField, message: "Please select lease"}]}>
                            <Select placeholder="Select lease" size="large">
                            {leases ? leases.map(lease => (<Option key={lease.id} value={lease.id}> {`${lease.property.building.name}, ${lease.property.name}. Lease #${lease.lease_number}`}</Option>) ) : null}
                            </Select>
                    </Form.Item>

                    <Form.Item label="Property" colon={false} hidden={!showPropertyField}>
                    <Row gutter={24 }>
                        <Col span={8}>
                        <Form.Item name="property" colon={false} hidden={!showPropertyField}
                            rules={[{required:showPropertyField, message: "Property is required"}]}>
                            <TreeSelect
                                style={{width: '100%'}}
                                showSearch

                                treeDefaultExpandAll
                                placeholder={'Search..'}
                                filterTreeNode={(search, item) => {
                                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                                }}

                            >
                                {properties.map(property => (
                                <TreeNode disabled  key={`B${property.id}`}   value={`B${property.id}`} title= {property.name}>
                                    {
                                    property.properties.map(apartment => (
                                        <TreeNode key={`${apartment.id}`} value={`${apartment.id}`} title={`${apartment.name}, Floor #:${apartment.rentable.floor_number}`}/>
                                    ))
                                    }
                                </TreeNode>
                                ))}
                            </TreeSelect>             
                        </Form.Item>
                        </Col>
                        <Col span={16}>
                        <Form.Item label="Unit" name="unit" colon={false} hidden={!showUnitField}
                            rules={[{required:showPropertyField, message: "Please select apartment unit"}]}>
                            <Select placeholder="Select Unit" style={{borderRadius:5}}>
                                <Option value="c">Contact1</Option>
                            </Select>
                        </Form.Item>
                        </Col>
                    </Row>

                    </Form.Item>

                    <Form.Item label="Remarks" name="name" colon={false}>
                        <TextArea placeholder="Enter something to help identify this transaction later" 
                        rows={4}
                        maxLength={255}/>
                    </Form.Item>
                    <Divider/>
                    <Form.Item label=" " colon={false}>
                        <Row style={{ textAlign: 'center' }}>
                            <Form.Item>
                                    <Button size="large" className="form-btn-big" type="secondary" onClick={()=> history.goBack()}>Cancel</Button>
                            </Form.Item>
                                <Form.Item>
                                    <Button size="large" className="form-btn-big" type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Row>
                    </Form.Item>
                    
                </Form>

            
        </React.Fragment>
    )
}



export default AddTransaction;
