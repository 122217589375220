import React, { useState } from 'react'
import useToggleState from '../../../hooks/useToggleState'
import {
  Card,
  Form,
  Button,
  Input,
  Avatar,
  Row,
  Col
} from 'antd'
import api from '../../../lib/api'
import TenantDrawer from './TenantDrawer'
import { SearchOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
}

const TenantCard = (props) => {
  const { tenant } = props
  const { Meta } = Card

  const unSelectedStyle = {
    backgroundColor: '#ecf0f1',
    border: '1px solid #bdc3c7',
    color: '#ccc',
    padding: '10px'
  }

  const errorStyle = {
    backgroundColor: '#ff7979',
    border: '1px solid #eb4d4b',
    color: '#fff',
    padding: '10px'
  }

  if(props.error) {
    return (
      <div style={errorStyle}>
        Please select a Tenant
      </div>
    )
  }

  if(tenant) {
    return (
      <Card
        style={{ width: '100%' }}
      >
        <Meta
          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
          title={tenant.name}
          description={tenant.identification}
        />
      </Card>
    )
  } else {
    return (
      <div style={unSelectedStyle}>
        No Tenant Selected
      </div>
    )
  }

}

function SelectTenant (props) {
  const { tenant, setTenant, toggleError } = props
  const [form] = Form.useForm()
  const [tenants, setTenants] = useState([])
  const [drawerVisible, toggleDrawerVisible] = useToggleState(false)

  const onClose = () => {
    toggleDrawerVisible()
    setTenants([])
  }

  const showDrawer = () => {
    toggleDrawerVisible()
  }

  const onSearchSubmit = (values) => {

    const data = {
      search_param: values.identification
    }

    api.post('api/tenant/search', data)
    .then(response => {
      console.log(response)
      setTenants(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })

    showDrawer()
    toggleError(false)
  }

  const onSearchSubmitFail = (errorInfo) => {
    console.log('Search fail', errorInfo)
  }

  return (
    <React.Fragment>

      {(tenant === null ) ? (
        <Form
          {...formItemLayout}
          form={form}
          layout={'horizontal'}
          labelAlign={'right'}
          onFinish={onSearchSubmit}
          onFinishFailed={onSearchSubmitFail}
        >
          <Form.Item
            label={'Tenant Search'}
            name="identification"
            rules={[{ required: true, message: 'Select or create a tenant to proceed' }]}
            style={{marginBottom: '8px'}}
            
          >
            <Input addonBefore={<SearchOutlined />} style={{ width: '100%' }} placeholder="Search by name" />
          </Form.Item>
          {/*<Form.Item label=" " colon={false} style={{marginBottom: '10px'}}>*/}
          {/*  <Button type="default" htmlType="submit">Search...</Button>*/}
          {/*</Form.Item>*/}
        </Form>
      ):(
        <></>
      )}


      <Row>
        <Col span={4} style={{textAlign: 'right'}}>
          {(tenant !== null) ? (
            <Button size={'sm'} danger onClick={() => setTenant(null)}>Clear</Button>
          ) : (
            <></>
           )}
        </Col>
        <Col span={14}>
          <TenantCard
            tenant={tenant}
            error={props.tenantError}
          />
        </Col>

      </Row>

      <TenantDrawer
        onClose={onClose}
        drawerVisible={drawerVisible}
        toggleDrawerVisible={toggleDrawerVisible}
        tenants={tenants}
        setTenant={setTenant}
        setTenants={setTenants}
        toggleError={toggleError}
      />

    </React.Fragment>
  )

}

export default SelectTenant