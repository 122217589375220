import React from 'react'
import {
  Drawer,
  Button,
  List,
  Avatar,
  Divider,
} from 'antd'
import TenantForm from './TenantForm'
import useToggleState from '../../../hooks/useToggleState'


function TenantDrawer (props) {

  const [displayCreateForm, toggleCreateFormDisplay] = useToggleState(false)
  const { onClose, drawerVisible, tenants, setTenant, toggleError, toggleDrawerVisible, tenantForEdit } = props

  return (
    <Drawer
      title={(tenantForEdit == null) ? ('Create') : ('Edit')}
      width={560}
      onClose={onClose}
      visible={drawerVisible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={onClose} >
            Close
          </Button>
        </div>
      }
    >
      

      <React.Fragment>
        <Divider />
        <TenantForm
          onClose={onClose}
          tenantForEdit={tenantForEdit}
          tenantUpdated={props.tenantUpdated}
          setTenant={props.setTenant}
          toggleCreateFormDisplay={() => {}}
          onDelete={props.onDelete}
        />
      </React.Fragment>


    </Drawer>
  )
}

export default TenantDrawer