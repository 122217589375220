// import api from '../lib/api'
//import axios from 'axios'

const reducer = (state, action) => {
  switch (action.type) {
    case 'STORE_TOKEN':

      // api.defaults.headers.common['Authorization'] = 'Bearer ' + action.data.access_token

      return {
        ...state,
        isAuthenticated: true,
        token: action.data.access_token,
        token_type: action.data.token_type,
        expires_in: action.data.expires_in
      }
    case 'CLEAR_TOKEN':
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        token_type: null,
        expires_in: null
      }
    default:
      return state
  }
}

export default reducer