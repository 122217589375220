import React, {useEffect, useState} from 'react'
import {
  Select,
  Row,
  Col,
  Space,
  TreeSelect
} from 'antd'
import api from '../../../lib/api'
import PropertyCard from './PropertyCard'
const { TreeNode } = TreeSelect;

function SelectProperty (props) {

  const { Option } = Select
  const [properties, setProperties] = useState([])
  const [selectedProperty, setSelectedProperty] = useState(null)

  useEffect(() => {
    api.get('/api/building/index?includeProperties=true')
    .then(response => {
      setProperties(response.data.data)
    })
    .catch(error =>  {
      console.log(error)
    })
  }, [])

  const onPropertySelect = (value) => {
    
    let valuesArray = value.split('-', 2)
    
    let building = properties.find(building => building.id == valuesArray[0])
    console.log(building)

    let property = building.properties.find(property => property.id == valuesArray[1])

    let selectedProperty = property

    props.setPropertyId(selectedProperty.id)
    props.setProperty(selectedProperty)
    props.toggleError(false)
    setSelectedProperty(selectedProperty)
  }

  const filter = (input, option) => {
    console.log(option)
    return true
    if(option.children == null){
      console.log(option)
    let valuesArray = option.split('-', 2)
    return option.children.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    
    return true
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={4} style={{textAlign: 'right'}} className={'ant-form-item-label'}>
          <label>Select Apartment</label>
        </Col>
        <Col span={14}>
          <Space direction={'vertical'} style={{width: '100%'}}>
          <TreeSelect
            style={{width: '100%'}}
            showSearch
            onSelect={onPropertySelect}
            treeDefaultExpandAll
            placeholder={'Search..'}
            filterTreeNode={(search, item) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}

          >
            {properties.map(property => (
              <TreeNode disabled  key={`${property.id}`}   value={`${property.id}`} title= {property.name}>
                {
                  property.properties.map(apartment => (
                    <TreeNode key={`${property.id}-${apartment.id}`} value={`${property.id}-${apartment.id}`} title={`${apartment.name}, Floor #:${apartment.rentable.floor_number}`}/>
                  ))
                }
              </TreeNode>
            ))}
          </TreeSelect>
          <PropertyCard property={selectedProperty} error={props.error}/>
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SelectProperty

