import React, {useEffect, useState} from 'react'
import DeleteButton from '../../components/DeleteButton'
import Apartment from './components/Apartment'
import Address from './components/Address'

import {
  Button,
  //Dropdown,
  //Menu,
  PageHeader,
  //List,
  Divider,
  Row,
  Col
} from 'antd';
import AppLayout from '../../layouts/main.layout'
import {
  Link,
  useParams,
  useHistory
} from 'react-router-dom'
import api from '../../lib/api'
import {
  CloseOutlined
} from '@ant-design/icons';

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const Room = (props) => {
  const { room } = props

  return (
    <React.Fragment>
      {/* <Row>
        <Col span={12}>
          <DescriptionItem title={'Name'} content={room.name} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={'Identifier'} content={room.identifier.toUpperCase()} />
        </Col>
      </Row> */}
      <Row>
        <Col span={12}>
          <DescriptionItem title={'Room Type'} content={room.room_type.name} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={'SQM'} content={room.sqm} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const ShowProperty = (type, property) => {
  if (type === 'Room') {
    return <Room room={property} />
  } else {
    return <Apartment apartment={property}/>
  }
}

const EditPropertyUrl = (type, propertyId) => {

  if(type === 'Apartment') {
    return '/properties/edit/apartment/'+propertyId
  } else {
    return '/properties/edit/room/'+propertyId
  }
}

function Property() {
  const history = useHistory();
  let { id } = useParams();
  const [property, setProperty] = useState(null)

  useEffect(() => {
    api.get('/api/property/show/'+id)
    .then(response => {
      setProperty(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [id])

  if(property == null) {
    return (
      <React.Fragment>
        <PageHeader
          className="site-page-header"
          onBack={() => null}
          title={'Property: '}
          subTitle="..."
        />
        loading...
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/properties')}
        title={'Unit: ' + property.name}
        subTitle={'Identifier: ' + property.identifier.toUpperCase()}
        extra={[
          <Link to={'/properties'} key={'1'}><Button icon={<CloseOutlined />} /></Link>,
        ]}
      />
      <p className="site-description-item-profile-p">General</p>
      <Row>
        <Col span={12}>
          <DescriptionItem title={'Unit'} content={property.name} />
        </Col>
        <Col span={12}>
        <DescriptionItem title={'Floor#'} content={property.floor} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title={'Insured'} content={(property.is_insured) ? 'Yes' : 'No'} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={'NFPA Standard'} content={property.nfpa_standard} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title={'Type'} content={property.property_type} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={'Owner'} content={property.owner.name} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title={'Created By'} content={property.created_by.name} />
        </Col>
      </Row>
      <Divider />
      <p className="site-description-item-profile-p">Property Details</p>
      {ShowProperty(property.property_type, property.rentable)}
      <Divider />
      <p className="site-description-item-profile-p">Address</p>
      <Address address={property.building.address} />
      <Divider />
      <Link to={EditPropertyUrl(property.property_type, property.id)}><Button>Edit</Button></Link>
      <DeleteButton
        text={'Delete'}
        url={'api/property/delete/'+property.id}
        deleteSuccessTitle={'Deleted'}
        deleteSuccessMessage={'Property deleted successfully'}
        redirectTo={'/properties'}
      />
    </React.Fragment>
  )
}

const PropertyPage = () => (
  <AppLayout>
    <Property />
  </AppLayout>
)

export default PropertyPage