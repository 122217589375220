import React from 'react'
import { Row, Col } from 'antd'

function SelectedBuilding (props) {

  const { building } = props
  const { address } = building

  let value = '';

  if(address.address_line_1) {
    value += address.address_line_1
  }

  if(address.address_line_2) {
    value += ' ' + address.address_line_2
  }

  if(address.address_line_3) {
    value += ' ' + address.address_line_3
  }



  return (
    <Row>
      <Col span={4}>
      </Col>
      <Col span={14}>
        <div style={{
          border: '1px solid #0984e3',
          backgroundColor: '#74b9ff',
          padding: '10px',
          color: '#fff'
        }}>
          <p><b>Build Name:</b> {building.name}</p>
          <p><b>Address:</b> {value}</p>
          <p><b>State:</b> {address.state.name}</p>
          <p><b>State:</b> {address.city.name}</p>
          <p><b>Country:</b> {address.country.name}</p>
        </div>
      </Col>
    </Row>
  )
}

export default SelectedBuilding