import React, { useContext } from 'react'
import { Layout, Menu, Dropdown, Button } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  EllipsisOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { AuthDispatchContext } from "../contexts/auth.context";
import api from '../lib/api'

function AppHeader (props) {
  const authDispatch = useContext(AuthDispatchContext)
  const history = useHistory();
  const { Header } = Layout;

  const headerStyles = {
    padding: '0',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }

  const triggerStyles = {
    fontSize: '18px',
    lineHeight: '64px',
    padding: '0 24px',
    cursor: 'pointer',
    transition: 'color 0.3s'
  }

  const appHeaderStyles = {
    paddingRight: '24px'
  }

  const logout = () => {
    api.get('/api/logout')
    .then(response => {
      authDispatch({type: 'CLEAR_TOKEN', data: response})
      history.push('/login')
    })
    .catch(error => {
      console.log(error)
    })
  }

  const menu = (
    <Menu>
      <Menu.Item>Profile</Menu.Item>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
    </Menu>
  )

  const DropdownMenu = () => {
    return (
      <Dropdown key="more" overlay={menu} >
        <Button
          style={{
            border: 'none',
            padding: 0,
          }}
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Button>
      </Dropdown>
    );
  };

  return (
    <Header className="site-layout-background" style={headerStyles}>
      <div>
      {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: props.toggle,
        style: triggerStyles
      })}
      </div>
      <div style={appHeaderStyles}>
        <DropdownMenu />
      </div>
    </Header>
  )
}

export default AppHeader
