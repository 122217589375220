import React, { useState, useCallback, useEffect } from 'react'
import AppLayout from '../../layouts/main.layout'
import {Button, Divider, PageHeader, Form, Input, InputNumber, Checkbox, Select, notification, Switch, Row, Col} from "antd";
import {
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import {
  CloseOutlined,
} from '@ant-design/icons';
import api from "../../lib/api";
import SelectBuilding from './components/SelectBuilding'
import DeleteButton from "../../components/DeleteButton";

function NewRoomPropertyForm () {

  const { Option } = Select;
  const [buildingId, setBuildingId] = useState(null)
  const [form] = Form.useForm()
  const history = useHistory();
  const [property] = useState(null);
  const [building, setBuilding] = useState(null)
  const [isEditing, setEditing] = useState(false)

  const [amenityTypeOptions, setAmenityTypes] = useState('')
  const [currencyOptions, setCurrencyOptions] = useState('')
  const [roomTypeOptions, setRoomTypeOptions] = useState([])

  let { id } = useParams()

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  }

  const amenitiyObjectValues = (objects) => {
    return objects.map( value => {
      return (value.slug)
    })
  }

  const SetFormValues = useCallback((data) => {
    form.setFieldsValue({
      name: data.rentable.name,
      nfpa_standard: data.nfpa_standard,
      sqm: data.rentable.sqm,
      room_type_id: data.rentable.room_type.id,
      is_insured: data.is_insured,
      default_rent: data.default_rent,
      currency: (data.currency) ? data.currency.slug : "mvr",
      default_security_deposit: data.default_security_deposit,
      default_utility_deposit: data.default_utility_deposit,
      amenities: amenitiyObjectValues(data.amenities),
      status: data.rentable.status
    })
  }, [form])

  useEffect(() => {

    api.get('/api/room_type/index')
    .then(response => {

      let options = []


      response.data.data.forEach(element => {
        options.push(<Option value={element.id} key={element.id}> {element.name}</Option>)

      });

      setRoomTypeOptions(options)

    })
    .catch(error => {
      console.log(error)
    })

    api.get('/api/amenity/index')
    .then(response => {

      let options = []

      response.data.data.forEach(element => {
        options.push(<Option value={element.slug} key={element.slug}> {element.name}</Option>)
      });
      setAmenityTypes(options)

    })
    .catch(error => {
      console.log(error)
    })

    api.get('/api/currency/index')
    .then(response => {

      let options = []
      let defCurrency = null

      response.data.data.forEach(element => {
        options.push(<Option value={element.slug} key={element.slug}> {element.name}</Option>)

      });

      setCurrencyOptions(options)

    })
    .catch(error => {
      console.log(error)
    })

    if(id !== undefined) {
      api.get('/api/property/show/'+id)
      .then(response => {
        let { data } = response.data
        SetFormValues(data)
        setBuilding(response.data.data.building)
        setBuildingId(response.data.data.building.id)
        setEditing(true)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }, [property, id, form, SetFormValues])

  const onFinish = (values) => {

    values.building_id = buildingId
    console.log('Success:', values)

    const key = `open${Date.now()}`;
    

    if(isEditing === true) {
      api.post('api/room/update/' + id, values)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      api.post('/api/room/store', values)
      .then(response => {
        console.log(response.data)

        const id = response.data.data.id;
        
        const btn = (
          <Button type={'primary'} size={'small'} onClick={() => history.push(`/properties/view/${id}`)}>
            View
          </Button>
        )

        notification.info({
          message: 'Property Created',
          description:
            'Your room property was successfully created.',
          onClick: () => {
            console.log('Notification Clicked!');

          },
          btn,
          key
        });
        history.push('/properties');

      })
      .catch(error => {
        console.log(error)
      })
    }

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <AppLayout>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Room"
        subTitle="New Room Property"
        extra={[
          <Link to={'/properties'} key={'1'}><Button icon={<CloseOutlined />} /></Link>,
        ]}
      />
      <Divider />
      <h3>Building</h3>

      <SelectBuilding
        isEditing = {isEditing}
        setBuildingId={setBuildingId}
        selectedBuilding={building}
        setSelectedBuilding={setBuilding}
      />

      <Divider />
      <h3>Room Details</h3>
      <Form
        {...formItemLayout}
        form={form}
        layout={'horizontal'}
        labelAlign={'right'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >


<       Form.Item label={'Unit'} name={'name'} rules={[{ required: true, message: 'Unit Name is required'}]}>
          <Input placeholder={'Unit Name (eg. 3A)'} />
        </Form.Item>
        
        <Form.Item label={'Floor #'} name={'floor_number'}>
          <Input placeholder={'Floor...'} />
        </Form.Item>
        <Form.Item label={'Room Type'} name={'room_type_id'} rules={[{required: true, message: 'Room Type is required'}]}>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={'Select Room Type'}
            
          >
            {roomTypeOptions}

          </Select>
        </Form.Item>
        
        {/* Amenities */}

        <Form.Item name={'amenities'} label={'Amenities'}>
            <Select
              mode="multiple"
              placeholder='Please select those applicable'
              style={{width:'100%'}}
              >
                {amenityTypeOptions}

              </Select>
        </Form.Item>

        <Divider/>
        <h3>Lease defaults</h3>

        <Form.Item 
          label={'Currency'} 
          name={'currency'}
          initialValue={"mvr"}
          >
          <Select>
            {currencyOptions}
          </Select>
        </Form.Item>


        <Form.Item label={'Rent'} name={'default_rent'} initialValue={5000}>
          <InputNumber 
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            min={0}
            
          />
        </Form.Item>
        
        <Form.Item label={'Deposits'} style={{ marginBottom: 0 }}>
          <Form.Item 
            label={'Security Deposit'} 
            name={'default_security_deposit'}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            initialValue={10000}
          >

            <InputNumber 
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              min={0}
              
            />
          </Form.Item>

          <Form.Item 
            label={'Utility Deposit'} 
            name={'default_utility_deposit'}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
            initialValue={2000}
          >
          <InputNumber 
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              min={0}
              
            />
          </Form.Item>
        </Form.Item>


        <Divider/>
        <h3>Miscelaneous Information</h3>
        
        <Form.Item label={'NFPA Standard'} name={'nfpa_standard'}>
          <Input placeholder={'NFPA Standard...'} />
        </Form.Item>
        
        

        <Form.Item name={"is_insured"} valuePropName={"checked"} label={'Insured'}>
          <Switch />
        </Form.Item>

        <Form.Item label={'Sqm.'} name={'sqm'} initialValue={45}>
          <InputNumber 
          min={1}
          max={1000}
          />
        </Form.Item>

        <Divider />

        <Row>
          <Col span={4}>
          <Form.Item >
          <Button type="primary" htmlType="submit">Save</Button>
        </Form.Item>
          </Col>
        <Col span={2}>
        {(isEditing) ? (
          <DeleteButton
            text={'Delete'}
            url={'api/property/delete/'+ id}
            deleteSuccessTitle={'Deleted'}
            deleteSuccessMessage={'Property deleted successfully'}
            redirectTo={'/properties'}
          />
        ): <></>}
        </Col>
        
        </Row>
        
      </Form>

    </AppLayout>
  )
}

export default NewRoomPropertyForm