import React, {useEffect, useState} from 'react'
import AppLayout from '../../layouts/main.layout'
import {
  Table,
  //Tag,
  PageHeader,
  Button,
  Dropdown,
  Menu,
  Tooltip
} from 'antd';
import {
  Link
} from "react-router-dom";
import {
  PlusOutlined
} from '@ant-design/icons';
import api from '../../lib/api'
import BuildingForm from './components/BuildingForm';


const menu = (
  <Menu>
    <Menu.Item>
      <Link to={'properties/add_new/apartment'} key={'1'}>Apartment</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={'properties/add_new/room'} key={'1'}>Room</Link>
    </Menu.Item>
  </Menu>
);

const address = (building) => {
  // let value = address.address_line_1 +
  //   ' ' + address.address_line_2 +
  //   ' ' + address.address_line_3

  let value = '';

  if(building.name){
    value += building.name + "/ "
  }

  if(building.address.address_line_1) {
    value += building.address.address_line_1
  }

  if(building.address.address_line_2) {
    value += ', ' + building.address.address_line_2
  }

  if(building.address.address_line_3) {
    value += ', ' + building.address.address_line_3
  }

  return value
}

const columns = [
  // {
  //   title: 'identifier',
  //   dataIndex: 'identifier',
  //   key: 'identifier'
  // },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => <Link to={`properties/view/${record.identifier}`}>{text}</Link>,
  },
  {
    title: 'Type',
    dataIndex: 'property_type',
    key: 'property_type',
  },
  {
    title: 'Building/Address',
    dataIndex: 'building',
    key: 'address',
    ellipsis: {
      showTitle: false,
    },
    render: (text, record) => {
      return (
        <Tooltip placement="topLeft" title={address(text)}>
          {address(text)}
        </Tooltip>
      )
    }
  }
];


//<Link to={'properties/add_new'} key={'1'}><Button icon={<PlusOutlined />} /></Link>

function PropertyList () {

  const [properties, setProperties] = useState([])

  useEffect(() => {
    api.get('/api/property/index')
    .then(response => {
      setProperties(response.data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [])



  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Properties"
        subTitle="Listing of all rentable properties"
        extra={[
          <Dropdown overlay={menu} placement="bottomRight" key={1}>
            <Button icon={<PlusOutlined />} />
          </Dropdown>,
        ]}
      />
      <Table columns={columns} dataSource={properties} rowKey={(record) => record.id}/>
    </React.Fragment>
  )
}

const Page = () => (
  <AppLayout>
    <PropertyList />
  </AppLayout>
)

export default Page