import React, {useEffect, useState, useCallback} from 'react'
import AppLayout from '../../layouts/main.layout'
import {
  Button,
  Divider,
  PageHeader,
  Form,
  Input,
  Select,
  notification,
  Checkbox,
  InputNumber,
  Row,
  Col
} from "antd";
import {
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import {
  CloseOutlined
} from '@ant-design/icons';
import api from "../../lib/api";
import SelectBuilding from './components/SelectBuilding'
import DeleteButton from "../../components/DeleteButton";
import { UIConst } from '../../constants/UIConst';


const amenitiyObjectValues = (objects) => {
  return objects.map( value => {
    return (value.slug)
  })
}

function ApartmentPropertyForm () {
  const { Option } = Select;
  const [form] = Form.useForm()
  const history = useHistory();

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  }

  let { id } = useParams()

  const [property] = useState(null);
  const [buildingId, setBuildingId] = useState(null)
  const [building, setBuilding] = useState(null)
  const [isEditing, setEditing] = useState(false)
  const [amenityTypeOptions, setAmenityTypes] = useState('')
  const [currencyOptions, setCurrencyOptions] = useState('')

  const SetFormValues = useCallback((data) => {
      console.log(data)
      form.setFieldsValue({
        name: data.name,
        nfpa_standard: data.nfpa_standard,
        sqm: data.rentable.sqm,
        floor_number: data.floor,
        apartment_type_id: data.rentable.apartment_type.id,
        is_insured: data.is_insured,
        num_bedrooms: data.rentable.num_bed_rooms,
        num_bathrooms: data.rentable.num_bath_rooms,
        address_line_1: data.building.address.address_line_1,
        address_line_2: data.building.address.address_line_2,
        address_line_3: data.building.address.address_line_3,
        postcode: data.building.address.post_code,
        country_id: data.building.address.country.id,
        state_id: data.building.address.state.id,
        city_id: data.building.address.city.id,
        default_rent: data.default_rent,
        currency: (data.currency != null) ? data.currency.slug : UIConst.Currency.MVR.slug,
        default_security_deposit: data.default_security_deposit,
        default_utility_deposit: data.default_utility_deposit,
        amenities: amenitiyObjectValues(data.amenities),
        meter_electricity: data.rentable.meter_electricity,
        meter_water: data.rentable.meter_water,
        status: data.rentable.status
      })
  }, [form])

  useEffect(() => {
    api.get('/api/amenity/index')
    .then(response => {

      let options = []

      response.data.data.forEach(element => {
        options.push(<Option key={element.slug}> {element.name}</Option>)
      });
      setAmenityTypes(options)

    })
    .catch(error => {
      console.log(error)
    })

    api.get('/api/currency/index')
    .then(response => {

      let options = []
      let defCurrency = null

      response.data.data.forEach(element => {
        options.push(<Option key={element.slug}> {element.name}</Option>)

      });

      setCurrencyOptions(options)

    })
    .catch(error => {
      console.log(error)
    })


    if(id !== undefined) {
      api.get('/api/property/show/'+id)
      .then(response => {
        console.log(response.data)
        let { data } = response.data
        
        SetFormValues(response.data.data)
        setBuilding(response.data.data.building)
        setBuildingId(response.data.data.building.id)
        setEditing(true)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }, [property, id, form, SetFormValues, setAmenityTypes])


  const onFinish = (values) => {

    values.building_id = buildingId

    console.log('Form Validation Success:', values)

    const key = `open${Date.now()}`;
    

    if(isEditing === true) {
      api.post('api/apartment/update/'+ id, values)
      .then(response => {
        const btn = (
          <Button type={'primary'} size={'small'} onClick={() => history.push(`/properties/view/${id}`)}>
            View
          </Button>
        )

        notification.info({
          message: 'Property Created',
          description:
            'Property was successfully updated.',
          onClick: () => {
            console.log('Notification Clicked!');

          },
          btn,
          key
        });

        history.push('/properties');
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      api.post('/api/property/apartment/store', values)
      .then(response => {
        console.log(response.data)
        const id = response.data.data.id;
        
        const btn = (
          <Button type={'primary'} size={'small'} onClick={() => history.push(`/properties/view/${id}`)}>
            View
          </Button>
        )

        notification.info({
          message: 'Property Created',
          description:
            'Your room property was successfully created.',
          onClick: () => {
            console.log('Notification Clicked!');

          },
          btn,
          key
        });

        history.push('/properties');
      })
      .catch(error => {
        console.log(error)
      })
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  console.log("editing:" + isEditing)
  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Apartment"
        subTitle="New Apartment Property"
        extra={[
          <Link to={'/properties'} key={'1'}><Button icon={<CloseOutlined />} /></Link>,
        ]}
      />

      <h3>Building</h3>

      <SelectBuilding
        isEditing = {isEditing}
        setBuildingId={setBuildingId}
        selectedBuilding={building}
        setSelectedBuilding={setBuilding}
      />

      <Form
        {...formItemLayout}
        form={form}
        layout={'horizontal'}
        labelAlign={'right'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Divider />
        <h3>Main Details</h3>

        <Form.Item label={'Unit'} name={'name'} rules={[{ required: true, message: 'Unit Name is required'}]}>
          <Input placeholder={'Unit Name (eg. 3A)'} />
        </Form.Item>
        
        <Form.Item label={'Floor #'} name={'floor_number'} rules={[{required: true, message: 'Floor is required'}]}>
          <Input placeholder={'Floor Number...'} />
        </Form.Item>

        <Form.Item label={'Apartment Type'} name={'apartment_type_id'} rules={[{required: true, message: 'Apartment Type is required'}]}>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={'Select Apartment Type'}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={1}>Studio</Option>
            <Option value={2}>Alcove Studio</Option>
            <Option value={3}>Convertible Studio</Option>
            <Option value={4}>Loft</Option>
            <Option value={5}>Penthouse</Option>
          </Select>
        </Form.Item>

        

        <Form.Item label={'Rooms'} style={{ marginBottom: 0 }}>

            <Form.Item
              name={'num_bedrooms'}
              rules={[{required: true, message: 'required'}]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              label={'Bedrooms'}
              initialValue={1}
            >
              <InputNumber  min={1} max={10}/>
            </Form.Item>
            <Form.Item
              name={'num_bathrooms'}
              rules={[{required: true, message: 'required'}]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
              label={'Bathrooms'}
              initialValue={2}
            >
              <InputNumber min={1} max={10}/>
            </Form.Item>

        </Form.Item>

        {/* Amenities */}

        <Form.Item name={'amenities'} label={'Amenities'}>
            <Select
              mode="multiple"
              placeholder='Please select those applicable'
              style={{width:'100%'}}
              >
                {amenityTypeOptions}

              </Select>
        </Form.Item>

        <Divider/>
        <h3>Lease defaults</h3>

        <Form.Item 
          rules={[{required: true, message: 'Currency is required'}]}
          label={'Currency'} 
          name={'currency'}
          initialValue={"mvr"}
          
          >
          <Select disabled>
            {currencyOptions}
          </Select>
        </Form.Item>


        <Form.Item label={'Rent'} name={'default_rent'} initialValue={5000}>
          <InputNumber 
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            min={0}
            
          />
        </Form.Item>
        
        <Form.Item label={'Deposits'} style={{ marginBottom: 0 }}>
          <Form.Item 
            label={'Security Deposit'} 
            name={'default_security_deposit'}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            initialValue={10000}
          >

            <InputNumber 
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              min={0}
              
            />
          </Form.Item>

          <Form.Item 
            label={'Utility Deposit'} 
            name={'default_utility_deposit'}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
            initialValue={2000}
          >
          <InputNumber 
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              min={0}
              
            />
          </Form.Item>
        </Form.Item>

        
        <Divider/>
        <h3>Miscelaneous Information</h3>
        

        <Form.Item label= {'Meter Numbers'} style={{ marginBottom: 0 }}>
        <Form.Item label={'Water'} name={'meter_water'} style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
          <Input placeholder={'Water Meter #'} />
        </Form.Item>
        <Form.Item label={'Electricity'} name={'meter_electricity'} style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
          <Input placeholder={'Electricity Meter #'} />
        </Form.Item>

        </Form.Item>
        <Form.Item label={'NFPA Standard'} name={'nfpa_standard'}>
          <Input placeholder={'NFPA Standard...'} />
        </Form.Item>
        <Form.Item name="is_insured" valuePropName="checked" label={'Insured'}>
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item label={'Sqm.'} name={'sqm'} initialValue={45}>
          <InputNumber 
          min={1}
          max={100000}
          />
        </Form.Item>


        <Divider />
        <Row>
          <Col span={4}>
            <Form.Item label=" " colon={false}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
          </Col>
          <Col span={2}>
          {(isEditing) ? (
          <DeleteButton
            text={'Delete'}
            url={'api/property/delete/'+ id}
            deleteSuccessTitle={'Deleted'}
            deleteSuccessMessage={'Property deleted successfully'}
            redirectTo={'/properties'}
          />
         ): <></>}
          </Col>
        </Row>
        
        

      </Form>

    </React.Fragment>
  )
}

const Page = () => {
  return (
    <AppLayout>
      <ApartmentPropertyForm />
    </AppLayout>
  )
}

export default Page;