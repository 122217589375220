import React from 'react'
import {
  Card
} from 'antd'

function PropertyCard (props) {

  const unSelectedStyle = {
    backgroundColor: '#ecf0f1',
    border: '1px solid #bdc3c7',
    color: '#ccc',
    padding: '10px'
  }

  const errorStyle = {
    backgroundColor: '#ff7979',
    border: '1px solid #eb4d4b',
    color: '#fff',
    padding: '10px'
  }

  const address = (address) => {
    let value = '';

    if(address.address_line_1) {
      value += address.address_line_1
    }

    if(address.address_line_2) {
      value += ' ' + address.address_line_2
    }

    if(address.address_line_3) {
      value += ' ' + address.address_line_3
    }

    return value
  }

  const { property } = props

  if(props.error) {
    return (
      <div style={errorStyle}>
        Please select a Property
      </div>
    )
  }

  if (props.property) {
    return (
      <Card bordered type="inner" title={`Property Name: ${property.rentable.name}`} extra={<a href="!#">More</a>}>
        <p><b>Type:</b> {property.property_type}</p>
        <p><b>Address:</b> {address(property.building.address)}</p>
      </Card>
    )
  } else {
    return (
      <div style={unSelectedStyle}>
        No Property Selected
      </div>
    )
  }
}

export default PropertyCard