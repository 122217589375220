import React from 'react';
import {Row, Col} from 'antd'

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

function Address (props) {
  const { address } = props

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <DescriptionItem title={'Address Line 1'} content={address.address_line_1} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title={'Address Line 2'} content={address.address_line_2} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title={'Address Line 3'} content={address.address_line_3} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title={'Post Code / ZipCode'} content={address.post_code} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title={'Sate'} content={address.state.name} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title={'City'} content={address.city.name} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title={'Country'} content={address.country.name} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Address