import React from 'react'
import {
  Drawer,
  Button,
  List,
  Avatar,
  Divider,
} from 'antd'
import TenantForm from '../../tenants/components/TenantForm'
import useToggleState from '../../../hooks/useToggleState'

const TenantCard = (props) => {

  const { tenants, onSelect, toggleDrawerVisible } = props

  const onTenantSelect = (item) => {
    onSelect(item)
    toggleDrawerVisible()
    //toggleError(false)
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={tenants}
      type={'inner'}
      renderItem={item => (
        <List.Item
          actions={[
            <Button key={1} onClick={() => onTenantSelect(item)}>Select</Button>
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
            title={<a href="https://ant.design">{item.name}</a>}
            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
          />
        </List.Item>
      )}
    />
  )
}

function TenantDrawer (props) {

  const [displayCreateForm, toggleCreateFormDisplay] = useToggleState(false)
  const { onClose, drawerVisible, tenants, setTenant, toggleError, toggleDrawerVisible } = props

  return (
    <Drawer
      title={'Select tenant or create one'}
      width={560}
      onClose={onClose}
      visible={drawerVisible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={onClose} >
            Close
          </Button>
        </div>
      }
    >
      {(tenants.length > 0 && displayCreateForm === false) ? (
        <React.Fragment>
          <TenantCard
            tenants={tenants}
            onSelect={setTenant}
            toggleTenantError={toggleError}
            toggleDrawerVisible={toggleDrawerVisible}
          />
          <Divider />
          <Button onClick={toggleCreateFormDisplay}>Create One</Button>
        </React.Fragment>
      ) : (
        (displayCreateForm === false) ? (
          <React.Fragment>
          No matching tenants found. <Button size={'small'} onClick={toggleCreateFormDisplay}>Create a new Tenant</Button>
        </React.Fragment>
        ) : (
          <></>
        )
        
      )}

      {(displayCreateForm) ? (
        <React.Fragment>
          <Divider />
          <TenantForm
            setTenant={setTenant}
            onClose={onClose}
            toggleCreateFormDisplay={toggleCreateFormDisplay}
          />
        </React.Fragment>
      ) : <></>}


    </Drawer>
  )
}

export default TenantDrawer