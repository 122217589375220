// import logo from './logo.svg';
import 'antd/dist/antd.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useRouteMatch,
  // useParams
} from "react-router-dom";
import {AuthProvider} from "./contexts/auth.context";
import DefaultPage from './components/DefaultPage'
import PrivateRoute from './components/PrivateRoute'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Properties from './pages/properties/Properties'
import Property from './pages/properties/Property'
// import AddNewProperty from './pages/NewPropertyForm'
import NewRoomPropertyForm from './pages/properties/NewRoomPropertyForm'
import ApartmentPropertyForm from './pages/properties/ApartmentPropertyForm'
import Leases from './pages/leases/Leases'
import LeaseForm from './pages/leases/LeaseForm'
import Lease from './pages/leases/Lease'
import Tenants from './pages/tenants/Tenants'
import TenantForm from './pages/tenants/components/TenantForm'
import Tenant from './pages/tenants/Tenant'
import Transactions from './pages/transactions/Transactions'
import AddTransaction from './pages/add_transaction/AddTransaction'
import AppLayout from './layouts/main.layout'

import {FORM_TYPE} from './pages/add_transaction/AddTransaction'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute path={'/'} exact><Dashboard /></PrivateRoute>
            <Route path={'/login'} exact component={Login} />
            <PrivateRoute path={'/dashboard'} exact><Dashboard /></PrivateRoute>
            <PrivateRoute path={'/properties'} exact><Properties /></PrivateRoute>
            <PrivateRoute path={'/properties/add_new'} exact><DefaultPage /></PrivateRoute>
            <PrivateRoute path={'/properties/add_new/room'} exact><NewRoomPropertyForm /></PrivateRoute>
            <PrivateRoute path={'/properties/add_new/apartment'} exact><ApartmentPropertyForm /></PrivateRoute>
            <PrivateRoute path={'/properties/view/:id'} exact><Property /></PrivateRoute>
            <PrivateRoute path={'/properties/edit/apartment/:id'} exact><ApartmentPropertyForm /></PrivateRoute>
            <PrivateRoute path={'/properties/edit/room/:id'} exact><NewRoomPropertyForm /></PrivateRoute>
            <PrivateRoute path={'/leases'} exact><Leases /></PrivateRoute>
            <PrivateRoute path={'/leases/new'} exact><LeaseForm /></PrivateRoute>
            <PrivateRoute path={'/leases/view/:id'} exact><Lease /></PrivateRoute>
            <PrivateRoute path={'/tenants/'} exact><Tenants/></PrivateRoute>
            <PrivateRoute path={'/tenants/new'} exact><TenantForm/></PrivateRoute>
            <PrivateRoute path={'/tenants/view/:id'} exact><Tenant/></PrivateRoute>
            <PrivateRoute path={'/transactions'} exact><Transactions/></PrivateRoute>
            <PrivateRoute path={'/transactions/add_new/property_income'} exact> 
              <AppLayout><AddTransaction formType={FORM_TYPE.IncomeProperty} title="Record a Property Income"/>
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={'/transactions/add_new/general_income'} exact> 
              <AppLayout>
                <AddTransaction formType={FORM_TYPE.IncomeGeneral} title="Record an Income"/>
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={'/transactions/add_new/property_expense'} exact> 
              <AppLayout><AddTransaction formType={FORM_TYPE.ExpenseProperty} title="Record a Property Expense"/>
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={'/transactions/add_new/general_expense'} exact> 
              <AppLayout>
                <AddTransaction formType={FORM_TYPE.ExpenseGeneral} title="Record an Expense"/>
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={'/transactions/add_new/provision'} exact> 
              <AppLayout>
                <AddTransaction formType={FORM_TYPE.Provisions} title="Record a non-cash expense"/>
              </AppLayout>
            </PrivateRoute>
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
