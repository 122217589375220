import React from "react";

const withLoginLayout = ({ children }) => {
  let styles = {
    backgroundColor: "#282c34",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    color: "#fff",
  };

  let colStyles = {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    width: '630px',
    height: '420px'
  };

  let splashContainerStyles = {
    width: "50%",
    border: "0px solid red",
  };

  let formContainerStyles = {
    width: "50%",
    border: "0px black solid",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "40px",
    paddingBottom: "30px",
  };

  let splashImageStyles = {
    width: "100%",
    height: "100%",
  };

  return (
    <div className="" style={styles}>

          <div style={colStyles}>
            <div className="login-spash" style={splashContainerStyles}>
              <img
                src={"/img/fahrul-azmi-RbH6aQAFfak-unsplash.jpg"}
                style={splashImageStyles}
                alt={"login-splash"}
              />
            </div>
            <div className="form-container" style={formContainerStyles}>
              {children}
            </div>
          </div>

    </div>
  );
};

export default withLoginLayout;
