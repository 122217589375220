import React, {
  useState,
  useCallback,
} from 'react'
import {
  Modal,
  Button,
  Form,
  Select,
  Input,
  DatePicker, notification
} from 'antd'
import moment from "moment";
import api from "../../../lib/api"
import { useEffect } from 'react'

export default function IncomeNoteModal (props) {
  const [form] = Form.useForm()
  const { Option } = Select
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [paymentMethodOptions, setPaymentMethodOptions] = useState('')

  let btnText = 'Income Note'
  let titleText = 'Income Note'

  /*
   * Form Styling
   */
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  }

  if(props.btnText) {
    btnText = props.btnText
  }

  if (props.titleText) {
    titleText = props.titleText
  }

  useEffect(() => {
    api.get('/api/payment-method/index')
    .then(response => {
      let paymentOptions =[]


      response.data.data.forEach( element => {
        paymentOptions.push(<Option key={element.id}>{element.name}</Option>)
      });
      setPaymentMethodOptions(paymentOptions)
    })
  }, [])


  const setFormValues = useCallback((data) => {
    form.setFieldsValue({
      amount: data.amount,
      type: data.type,
      received_on: moment(data.received_on, 'YYYY-MM-DD'),
      reference: data.reference
    })

  }, [form])


  const showModal = () => {
    setVisible(true)

    if(props.isEditing === true) {
      api.get(`/api/paid-note/show/${props.editingId}`)
      .then(response => {
        //console.log(response)
        let { data } = response.data
        setFormValues(data)
      })
      .catch(error => {
        console.log(error)
      })
    }else{
      setFormValues({amount:props.amount, type: 'bank-transfer', received_on: new Date()})
    }

  }

  const handleOk = () => {
    console.log('Clicked OK button')
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };

  const updateNoteRecord = (data) => {
    if (props.updateNote) {
      props.updateNote( props.transactionId, data )
    }

    if(props.refreshDepositNotes) {
      props.refreshDepositNotes()
    }
  }

  const onFinish = (values) => {

    let url = '/api/paid-note/store'

    if (props.isEditing) {
      url = `/api/paid-note/update/${props.editingId}`
    }

    setConfirmLoading(true)

    values.lease_id = props.leaseId
    values.transaction_id = props.transactionId
    values.receivable_type = props.receivableType

    console.log('Income Note Form Success', values)

    api.post(url, values)
    .then(response => {
      notification.open({
        message: 'Success',
        description: `Payment Confirmed`,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });

      updateNoteRecord(response.data.data)

      if(props.fetchDrawerData) {
        props.fetchDrawerData()
      }
    })
    .catch(error => {
      console.log(error)
      if(error.response.status === 422) {
        console.log(error.response.data)
      } else if (error.response.status === 409) {
        notification.open({
          message: 'Error',
          description: `${error.response.data.message}`,
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    })

    setConfirmLoading(false)
    setVisible(false)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Income Note Form Failed:', errorInfo);
  };

  return (
    <>
      <Button  type="primary" onClick={showModal} style={{...props.btnStyle, marginBottom: '0px'}}>
        {btnText}
      </Button>
      <Modal
        title={titleText}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        destroyOnClose
        footer={[
          <Button key={'cancel'} onClick={handleCancel}>Cancel</Button>,
          <Button loading={confirmLoading} type={'primary'}  form="paid-note-form" key="submit" htmlType="submit">Confirm</Button>,
        ]}
      >
        <Form
          form={form}
          {...formItemLayout}
          id={"paid-note-form"}
          layout={'horizontal'}
          labelAlign={'right'}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={'Amount'}
            name={'amount'}
            rules={[{required: true, message: 'Amount is required'}]}
            >
            <Input placeholder={'Amount'} />
          </Form.Item>

          <Form.Item label={'Method'} name={'payment_method_id'} rules={[{required: true, message: 'Payment method is required'}]}>
            <Select
              showSearch
              placeholder={'Select Type'}
              optionFilterProp="children"
            >
              {paymentMethodOptions}
            </Select>
          </Form.Item>

          <Form.Item
            label={'Date'}
            name={'received_on'}
            rules={[{required: true, message: 'Date is required'}]}
            >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label={'Ref'}
            name={'reference'}
            rules={[{required: true, message: 'Reference is required'}]}
          >
            <Input placeholder={'Reference'} />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );

}